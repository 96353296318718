:root {
  --lightBlue: #0094ff;
}
@import url("https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap");
.wrapper {
  display: inline-flex;
  height: 45px;
  width: 451px;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
}
.wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -2px;
  border-radius: 0px;
  cursor: pointer;
  border: 1px solid lightgrey;
  transition: all 0.3s ease;
}

/* Please nake some diffrent class here */
/* input[type="radio"] {
  display: none;
} */

#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  cursor: pointer;
  border-color: #2b0b58;
  background: #2b0b58;
}
.wrapper .option span {
  font-size: 18px;
  color: #2b0b58;
  font-weight: 500;
  cursor: pointer;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #fff;
  cursor: pointer;
}

.checkBoxCustome {
  font-family: system-ui, sans-serif;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.1em;
}

.termsConditionSignInpage {
  display: flex;
  align-items: baseline;
}
.skyBlue {
  color: var(--lightBlue);
  cursor: pointer;
}
