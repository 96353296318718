.image-container {
    display: flex;
    flex-wrap: wrap;
  }
  .image-wrapper {
    position: relative;
    margin: 5px;
    border: 0px solid red;
    border-radius: 5px;
    }
  .image {
    width: 200px;
    height: 200px;
  }
  
  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    /* background-color: red; */
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 50%;
    /* width: 20px;
    height: 20px; */
    cursor: pointer;
  }

  input[type=file] {
    color: #444;
    padding: 5px;
    border-radius: 0px;
    border: 1px solid #555;
    /* height: 50px;
    width: 1690px; */
    /* opacity: 0; */
    cursor: pointer;
  }
  .inputFileUpload{
    background-color: #E9F1FF;
   text-align: center;
    padding: 10px;
    border: 2px dashed #444;
    cursor: pointer;
  }
  .uploadfile{
    position: relative;
  }
  .handleImage{
    position: absolute;
    top: -4px;
  }
.uploadfile h1{
    font-size: large;
    font-weight: 600;
}