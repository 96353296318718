body {padding: 0;margin: 0;}
body * {box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;}
input,
button{outline: none; border: none; box-shadow: none;}
textarea{min-height: 100px;}
figure {margin: 0;position: relative;}
figure img {position: relative;z-index: 1;}

.overMain {
  background-color: #fff;
  padding: 25px;
}
.overTitle {
  font-size: 24px;
  color: #001a5c;
  font-weight: 500;
  margin-bottom: 50px;
}
.overTitle_1 {
  font-size: 20px;
  color: #001a5c;
  font-weight: 500;
  margin-bottom: 50px;
}
.ovIcon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #0094ff;
  text-align: center;
  color: #fff;
  font-size: 25px;
  line-height: 48px;
  margin-right: 20px;
}

.ovTitle p {
  font-size: 16px;
  color: #5f718d;
  font-weight: 400;
  margin-bottom: 5px;
}
.ovTitle h3 {
  font-size: 14px;
  color: #001a5c;
  font-weight: 500;
}
.listOverview {
  display: flex;
  flex-wrap: wrap;
}
.listOverview li {
  list-style: none;
  flex: 0 0 50%;
  display: flex;
  margin-bottom: 50px;
  position: relative;
  align-items: center;
}
.lineBg:after {
  height: 100%;
  width: 1px;
  background-color: #e1e1e1;
  content: "";
  position: absolute;
  right: 3%;
  top: 0;
}

.tableData tr td {
  font-size: 16px !important;
  color: #001a5c;

  padding: 12px;
  white-space: nowrap;
  vertical-align: baseline;
}
.tableData tr th {
  font-size: 16px;
  color: #5f718d;
  font-weight: 400;
  white-space: nowrap;
}
.tableData tr .docTitle {
  color: #0094ff;
}
.tableData tr .pending {
  background: rgba(249, 161, 17, 0.1);
  border-radius: 50px;
  width: auto;
  padding: 0px 22px;
  height: 33px;
  color: #f9a111;
  display: inline-block;
  text-align: center;
  line-height: 33px;
}
.tableData tr .success {
  background: rgba(97, 255, 226, 0.1);
  border-radius: 50px;
  width: auto;
  padding: 0px 22px;
  height: 33px;
  color: #0cd7b2;
  display: inline-block;
  text-align: center;
  line-height: 33px;
  font-weight: 300;
}
.tableData tr .not-support {
  background: rgba(255, 85, 72, 0.1);
  border-radius: 50px;
  width: auto;
  padding: 0px 22px;
  height: 33px;
  color: #ff5548;
  display: inline-block;
  text-align: center;
  line-height: 33px;
}
.tableData tr .processed {
  background: rgba(97, 15, 200, 0.1);
  border-radius: 50px;
  width: auto;
  padding: 0px 22px;
  height: 33px;
  color: #610fc8;
  display: inline-block;
  text-align: center;
  line-height: 33px;
}
.viewPage {
  border: 1px solid #0094ff;
  border-radius: 50px;
  width: 143px;
  height: 38px;
  background-color: #fff;
  color: #0094ff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s;
}
.viewPage svg {
  margin-left: 10px;
}
.viewPage:hover {
  border: 1px solid #0094ff;
  background-color: #0094ff;
  color: #fff;
}

.totalValue {
  background-color: #fff;
  padding: 25px;
}
.totalTitle p {
  color: #5f718d;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 5px;
}
.totalTitle h3 {
  color: #001a5c;
  font-size: 22px;
  font-weight: 500;
}
.totalTitle h3 span {
  color: #0cd7b2;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}
.totalTitle h3 span svg {
  margin-right: 5px;
  font-weight: bold;
}

.listDate {
  display: inline-block;
}
.listDate li {
  display: inline-block;
  background-color: #f1f8fd;
  font-size: 16px;
  color: #001a5c;
  width: 47px;
  height: 37px;
  border-radius: 5px;
  text-align: center;
  line-height: 37px;
  margin-left: 8px;
}
.listDate li.active {
  background-color: #0094ff;
  color: #fff;
}

.notificationList .ovIcon {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #0094ff;
  text-align: center;
  color: #fff;
  font-size: 25px;
  line-height: 48px;
  margin-right: 20px;
}
.notificationList .ovTitle p {
  font-size: 16px;
  color: #5f718d;
  font-weight: 400;
  margin-bottom: 5px;
}
.notificationList .ovTitle h3 {
  font-size: 20px;
  color: #0094ff;
  font-weight: 400;
  cursor: pointer;
}
.notificationList {
  display: block;
}
.notificationList li {
  list-style: none;
  flex: 0 0 100%;
  display: flex;
  margin-bottom: 50px;
  position: relative;
}
.ViewBtn {
  background-color: #0094ff;
  width: 78px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 50px;
  border: none;
}
.backBtn {
  width: 40px;
  height: 40px;
  border: 2px solid #5f718d;
  border-radius: 50px;
  background-color: #fff;
}

.earnList li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #5f718d;
  margin-bottom: 20px;
}
.earnList li span {
  color: #5f718d;
  font-weight: 600;
}
.earnImage {
  position: relative;
}
.earnImage:before {
  height: 100%;
  width: 1px;
  background-color: #e1e1e1;
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
}
.line {
  position: relative;
}
.line:before {
  height: 100%;
  width: 1px;
  background-color: #e1e1e1;
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
}

/*==================================== Subject: Transaction Approved Page =================================*/
.prograss li {
  text-align: center;
  list-style: none;
  font-size: 16px;
  color: #5f718d;
}
.prograss span {
  display: block;
  width: 36px;
  height: 36px;
  background: #0094ff;
  color: #fff;
  border-radius: 50%;
  line-height: 36px;
  margin: 0 auto;
  margin-bottom: 10px;
  font-weight: 500;
}
.prograssBar {
  background: #f1f8fd;
  border-radius: 50px;
  height: 10px;
  position: relative;
}
.prograssBar .bar {
  background: #0094ff;
  border-radius: 50px;
  height: 10px;
  position: relative;
  width: 25%;
}
.circleBar {
  box-shadow: 0px 0px 5px rgba(167, 199, 222, 0.5);
  width: 24px;
  height: 24px;
  position: absolute;
  background: #0094ff;
  border-radius: 50%;
  border: 10px solid #fff;
  border: 5px solid #fff;
  top: -8px;
  right: 0;
}
.preBar {
  position: absolute;
  width: 45px;
  height: 45px;
  right: -12px;
  top: -71px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(167, 199, 222, 0.5);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 45px;
}
.preBar::after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(255, 255, 255);
  content: "";
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.houseRenovation {
  background-color: #001a5c;
}
.houseRenoTitle h1 {
  font-size: 40px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: normal;
}
.houseRenoTitle p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 50px;
  line-height: 26px;
}
.houseRenoTitle ul {
  display: flex;
  flex-wrap: wrap;
}
.houseRenoTitle ul li {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  border-radius: 50px;
  flex: 0 0 55%;
  list-style: none;
  padding: 6px 9px;
  font-size: 16px;
  margin-bottom: 18px;
  margin-right: 12px;
}
.houseRenoTitle ul li span {
  font-weight: 600;
}
.houseRenoTitle ul li svg {
  font-size: 25px;
  margin-right: 14px;
  width: 24px;
}
.houseRenoTitle ul li figure {
  width: 24px;
  display: inline-block;
}
.softSLider {
  font-size: 18px;
  padding: 20px;
  text-align: left;
}
.reviewList {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1e1e1;
}
.reviewList li {
  flex: 0 0 50%;
  margin-bottom: 20px;
  list-style: none;
  color: #5f718d;
  font-size: 16px;
  font-weight: 500;
}
/* .reviewList li:last-child {
  flex: 0 0 100%;
} */
.reviewList li span {
  display: block;
  color: #001a5c;
  font-weight: 600;
  margin-top: 8px;
}
.reviewList li input {
  border: 1px solid #e1e1e1;
  height: 34px;
  padding: 0 10px;
  color: #5f718d;
}

.infoMain {
  border-bottom: 1px solid #dbdee9;
}
.infoMain h4 {
  font-size: 20px;
  font-weight: 500;
  color: #001a5c;
}
.personalInfo {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1e1e1;
}
.personalInfo li {
  flex: 0 0 33%;
  margin-bottom: 20px;
  list-style: none;
  color: #5f718d;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}
.personalInfo li span {
  display: block;
  color: #001a5c;
  font-weight: 600;
  margin-top: 8px;
}
.personalInfo li input {
  border: 1px solid #e1e1e1;
  height: 34px;
  padding: 0 10px;
  color: #5f718d;
}

.processingInfo li {
  display: flex;
  margin-bottom: 20px;
  list-style: none;
  color: #5f718d;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
  justify-content: space-between;
}
.processingInfo li span {
  color: #001a5c;
  font-weight: 600;
  margin-top: 8px;
}
.viedProfile {
  width: auto;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  padding: 7px 25px;
}

.totalPay h4 {
  font-size: 20px;
  font-weight: 500;
  color: #001a5c;
}
.totalPay span {
  font-size: 20px;
  font-weight: 600;
  color: #001a5c;
}
.totalPay {
  color: #5f718d;
  font-size: 16px;
  font-weight: 500;
}

.walletBalance {
  display: flex;
  flex-wrap: wrap;
}
.walletBalance li {
  flex: 0 0 50%;
  margin-bottom: 20px;
  list-style: none;
  color: #001a5c;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}
.walletBalance li span {
  display: block;
  color: #001a5c;
  font-weight: 700;
  margin-top: 8px;
}

.addBalanceBtn {
  width: 137px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border: none;
  transition: all 0.5s;
}
.addBalanceBtn:hover {
  background-color: #001a5c;
}
.submitOffer {
  text-align: center;
}
.submitOffer button {
  text-align: center;
  width: 314px;
  height: 69px;
  background: #0094ff;
  border-radius: 50px;
  border: none;
  font-weight: 700;
  transition: all 0.5s;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
}
.submitOffer button:hover {
  background-color: #001a5c;
}

/*========================Investment acknowledgement questions Popup Css ===================*/
.investPopup {
  text-align: center;
  width: 200px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  border: none;
  font-weight: 600;
  transition: all 0.5s;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
}
.aknolodgepopup {
  position: absolute;
  max-width: 800px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);
}
.aknolodgepopup h3 {
  color: #001a5c;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.investAck {
  padding: 0;
}
.investAck li {
  padding: 0;
  /* color: #001a5c; */
  font-size: 13px;
  margin-bottom: 10px;
  list-style: none;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 8px;
  line-height: 17px;
}
.investAck li input[type="checkbox"] {
  /* height: 30px;
  width: 30px; */
  margin-right: 15px;
  border-color: #000;
}
.closeBtn {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 10px;
}

/*========================End of Investment acknowledgement questions Popup Css ===================*/

.downLoadBtn {
  display: flex;
}
.downLoadBtn li {
  list-style: none;
  margin-left: 10px;
}
.downLoadBtn li button {
  width: 40px;
  height: 40px;
  background-color: #0094ff;
  border-radius: 50%;
  text-align: center;
  border: none;
  color: #fff;
  font-size: 22px;
  line-height: 20px;
  transition: all 0.5s;
}
.downLoadBtn li button:hover {
  background-color: #001a5c;
}
.ViewBtn.disabled {
  background-color: #eeeeee;
  color: #000;
  color: #b1b1b1;
}
.orderNumber {
  background: #f1f8fd;
  padding: 20px;
}
.orderNumber span {
  color: #5f718d;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.orderNumber h4 {
  color: #001a5c;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}
.cardDetail {
  border: 1px solid #e1e1e1;
  padding: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.cardDetail .cardList {
  flex: 0 0 30%;
}
.cardDetail .cardForm {
  flex: 0 0 60%;
}

.cardList ul {
  padding: 0;
}
.cardList ul li {
  display: block;
}
.cardList ul li label {
  display: block;
  background-color: #f1f8fd;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.cardList ul li label input {
  margin-right: 10px;
}
.saveCard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.saveCard li {
  list-style: none;
  margin-right: 20px;
}
.saveCard li button {
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  color: #5f718d;
  border: none;
  padding: 8px 30px;
}
.saveCard li button.active {
  background: #0094ff;
  color: #fff;
}

#payment-form input {
  border: 1px solid #e1e1e1;
  height: 66px;
  color: #001a5c;
  border-radius: 0;
  margin-bottom: 20px;
}
#payment-form input:focus {
  box-shadow: none;
}

.subscribe button {
  background: #0094ff;
  border-radius: 50px;
  width: 100%;
  height: 49px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  border: none;
  transition: all 0.5s;
}
.subscribe button:hover {
  background-color: #001a5c;
}
.payment-errors {
  color: #001a5c;
  font-size: 16px;
  margin-top: 10px;
}

.minHeight {
  min-height: 500px;
}
.watingApproval {
  max-width: 480px;
  text-align: center;
}
.watingApproval h3 {
  font-weight: 500;
  font-size: 24px;
  color: #001a5c;
  margin-bottom: 20px;
}
.watingApproval p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #001a5c;
  margin-bottom: 30px;
  line-height: 27px;
}
.watingApproval button {
  width: 118px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-weight: 700;
}
.watingApproval span {
  background: #0cd7b2;
  height: 100px;
  width: 100px;
  display: inline-block;
  border-radius: 50%;
  line-height: 90px;
  font-size: 50px;
  color: #fff;
  margin-bottom: 25px;
}

/*========================My Wallet Page ===================*/
.walletTab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 35px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 32px;
}
.walletTab li {
  list-style: none;
  margin-right: 20px;
}
.walletTab li button {
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
  color: #5f718d;
  border: none;
  padding: 8px 30px;
}
.walletTab li button.active {
  background: #0094ff;
  color: #fff;
}
h3.blue {
  color: #0094ff;
}
.addAcount {
  background: #0094ff;
  border-radius: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
  font-weight: 600;
  transition: all 0.5s;
}
.addAcount:hover {
  background-color: #001a5c;
}

.linkAcount label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #001a5c;
  padding-bottom: 10px;
}
.listBank {
  background-color: #fff;
  border-radius: 5px;
  padding: 16px 25px;
  color: #001a5c;
  font-weight: 600;
  margin-bottom: 20px;
}
.listBank p,
.listBank a {
  font-size: 16px;
  font-weight: 600;
}
.listBank img {
  margin-right: 15px;
}
.addNewAcnt {
  border-top: #e1e1e1 solid 1px;
  margin-top: 25px;
  padding-top: 25px;
}
.addNewAcnt h2 {
  margin-bottom: 25px;
}
.traHistory {
  border-top: 10px solid #f1f8fd;
  /* margin-top: 50px;
  padding-top: 50px; */
}
.addNewAcnt label {
  font-size: 20px;
  color: #001a5c;
  margin-bottom: 10px;
}
.addNewAcnt input {
  height: 56px;
  border: 1px solid #dbdee9;
  width: 100%;
  padding: 10px 15px;
  color: #001a5c;
  font-weight: 500;
  font-size: 15px;
}
.addNewAcnt select {
  height: 56px;
  border: 1px solid #dbdee9;
  width: 100%;
  padding: 10px 15px;
  color: #001a5c;
  font-weight: 500;
  font-size: 15px;
  background-color: #fff;
}
.linkBtn {
  width: 163px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
.linkBtn svg {
  font-size: 25px;
  margin-right: 10px;
}

.clr1,
.clr2,
.clr3 {
  background-color: #61ffe3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
.clr2 {
  background-color: #f9a214;
}
.clr3 {
  background-color: #fb463a;
}

.downLoadBtn li input {
  border: 1px solid #dbdee9;
  height: 46px;
  padding: 0 35px 0 15px;
}
.downLoadBtn li {
  position: relative;
}
.downLoadBtn li img {
  position: absolute;
  right: 10px;
  top: 12px;
}
.tableData .skyBg {
  background-color: #f1f8fd;
  font-size: 20px !important;
  font-weight: 600 !important;
}

/*========================My Investments Page ===================*/
.listInvest li {
  flex: 0 0 33%;
}
.w-40 {
  width: 40px;
}
.blueBtn {
  width: 99px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
}
.redBtn {
  width: 99px;
  height: 40px;
  background: #fff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #ff5548;
  border: 1px solid #ff5548;
}
.projectBtn {
  background: none;
}
.tetherMain {
  border-top: 1px solid #e1e1e1;
  padding-top: 30px;
  align-items: center;
  margin-right: 30px;
}
.tetherTitle {
  font-size: 24px;
  font-weight: 600;
  color: #001a5c;
}
.brdNone {
  border: none;
}

.projectMainBox {
  background-color: #001a5c;
  padding: 50px;
  position: relative;
}
.projectMainBox::after {
  height: 100%;
  content: "";
  width: 1px;
  background: #e1e1e1;
  display: block;
  position: absolute;
  left: -23px;
  top: 0;
}
.projectMainBox h2 {
  font-size: 33px;
  color: #fff;
  margin-bottom: 10px;
}
.projectMainBox p {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  line-height: 30px;
  /* width: 80%; */
}
.starLog {
  text-align: center;
  margin-bottom: 30px;
}
.starLog img {
  max-width: 150px;
  height: 150px;
  width: 100%;
  object-fit: cover;

}
.starRating {
  color: #fff;
  font-size: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #002074;
}
.quater{
  width: 100%;
}
.quater h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.quater ul li {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
  list-style-type: none;
}

.updateBox {
  padding: 50px;
  margin-bottom: 20px;
}
.updateBox h2 {
  color: #001a5c;
  font-size: 30px;
  font-weight: 400;
  line-height: 37px;
}
.updateBox .date {
  color: #5f718d;
  font-size: 20px;
  font-weight: 500;
}
.updateBox p {
  color: #5f718d;
  font-size: 16px;
  line-height: 25px;
  
}

.likes {
  border-top: 1px solid #ccc;
  padding-top: 30px;
}
.downBtn {
  background: #0094ff;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  line-height: 35px;
}
.likeBtn {
  width: 163px;
  height: 58px;
  border: 1px solid #dbdee9;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #001a5c;
  text-align: center;
  line-height: 58px;
}
.likeBtn svg {
  font-size: 25px;
  margin-right: 10px;
}
.dateInfo {
  flex: 0 0 25%;
  border-right: 1px solid #ccc;
}
.dateInfo span {
  display: block;
  width: 110px;
  height: 34px;
  border: 1px solid #dbdee9;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #001a5c;
  line-height: 34px;
  margin: 15px auto;
}
.dueTitle {
  font-size: 20px;
  color: #001a5c;
  font-weight: 500;
}

.openDownload {
  width: 218px;
  height: 39px;
  background: #0094ff;
  border-radius: 50px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
}
.openDownload svg {
  font-size: 25px;
}
.openDownload:hover {
  background-color: #001a5c;
}

.otpList {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.otpList li {
  /* width: 46px;
  margin-right: 24px; */
  list-style-type: none;
}
.otpList li input {
  width: 56px;
  padding: 18px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #001a5c;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.twoFactor .codeMsg p {
  font-size: 16px;
  color: #5f718d;
  padding: 0;
  margin: 0;
}
.codeMsg span {
  font-weight: 600;
}
.codeMsg a {
  color: #0094ff;
  text-decoration: underline;
  display: inline-block;
  padding-top: 8px;
}

.barCode {
  border: 1px solid #dbdee9;
  max-width: 700px;
  display: flex;
}
.barCode .codeImg {
  padding: 20px;
  border-right: 1px solid #ccc;
  flex: 0 0 25%;
}
.barCode p {
  padding: 0;
  margin: 0;
}
.barCode h5 {
  color: #001a5c;
  font-size: 24px;
  font-weight: 600;
}
.disableBtn {
  border: 1px solid #ff5548;
  border-radius: 50px;
  color: #ff5548;
  font-size: 16px;
  background-color: #fff;
  padding: 8px 25px;
  font-weight: 600;
  margin-top: 20px;
  transition: all 0.5s;
}
.disableBtn:hover {
  background-color: #ff5548;
  color: #fff;
}

/*========================Settings Page ===================*/
.customTab li.nav-item button {
  background: #fff;
  font-size: 16px;
  color: #5f718d;
  font-weight: 500;
  padding: 10px 36px;
  border-radius: 50px;
  margin-right: 30px;
  border: none;
}
.customTab li.nav-item button.active,
.customTab li.nav-item button:hover {
  background: #0094ff;
  color: #fff;
}
.setingDetail li figure {
  position: relative;
}
.setingDetail li figure,
.setingDetail li figure img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 25px;
  background: #eee;
}
.userContent h3 {
  font-size: 17px;
  font-weight: 500;
  color: #001a5c;
  margin-bottom: 8px;
}
.userContent p {
  color: #5f718d;
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin-bottom: 4px;
}

.lastLogin h6 {
  color: #5f718d;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
}
.lastLogin p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #001a5c;
  margin: 0;
}
.unChnage {
  background: rgba(255, 85, 72, 0.1);
  border-radius: 50px;
  width: auto;
  padding: 0px 22px;
  height: 33px;
  color: #ff5548;
  display: inline-block;
  text-align: center;
  line-height: 33px;
}
.editBtn {
  position: absolute;
  width: 38px;
  height: 38px;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #0094ff;
  border: 3px solid #ffffff;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  line-height: 10px;
}

.editBtnDetails {
  position: absolute;
  top: 65%;
  right: 0;
  transform: translateY(-50%);

  width: 38px;
  height: 38px;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #0094ff;
  border: 3px solid #ffffff;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  line-height: 10px;
}

.customTab {
  border-bottom: 1px solid #efefef;
  padding-bottom: 25px;
}
/* .form input {
  border: 1px solid #dbdee9;
  height: 56px;
  padding: 0 35px 0 15px;
  border-radius: 0;
  color: #5f718d;
} */
/* .form textarea {
  border: 1px solid #dbdee9;
  height: 150px;
  padding: 0 35px 0 15px;
  border-radius: 0;
  color: #5f718d;
} */
.form input:focus {
  box-shadow: none;
}
.socialTitle {
  padding: 0;
}
/* .socialTitle input {
  padding: 0 15px 0 40px;
} */
.socialTitle h3 {
  color: #001a5c;
  font-size: 24px;
  font-weight: 600;
  margin-top: 25px;
  padding-bottom: 25px;
}
.socialTitle span {
  position: absolute;
  left: 20px;
  bottom: 10px;
  font-size: 20px;
  color: #1da1f2;
}

.doneBtn {
  border-top: 1px solid #efefef;
  padding-top: 15px;
  margin-top: 25px;
}

.doneBtn button {
  width: 91px;
  height: 38px;
  background: #0094ff;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.doneBtn1 {
  padding-top: 15px;
  margin-top: 25px;
}

.doneBtn1 button {
  width: 91px;
  height: 38px;
  background: #0094ff;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.fileupload {
  position: relative;
  width: 256px;
  height: 256px;
  margin: 32px auto;
}
.fileupload img {
  width: 256px;
  height: 256px;
  border-radius: 50%;
  background-color: #f1f8fd;
  line-height: 200px;
}
.fileupload input {
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  height: 256px;
  width: 256px;
  border-radius: 25%;
}
.fileupload svg {
  font-size: 100px;
  color: #c3d1e8;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0 auto;
  height: 230px;
}

.passTitle {
  margin: 40px 0px;
}
.passTitle h2 {
  color: #001a5c;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}
.passTitle p {
  color: #5f718d;
  font-size: 16px;
  font-weight: 500;
}

.comnBtn {
  background-color: #0094ff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  padding: 10px 25px;
}
.twoFactor h2 {
  font-size: 24px;
  font-weight: 500;
  color: #001a5c;
  margin-bottom: 20px;
}
.twoFactor h4 {
  font-size: 20px;
  font-weight: 500;
  color: #001a5c;
  margin-bottom: 5px;
}
.twoFactor p {
  font-size: 16px;
  font-weight: 500;
  color: #5f718d;
  margin-bottom: 15px;
}
.twoFactor p.enterOTP {
  font-size: 32px;
  font-weight: 600;
  color: #001A5C;
  margin-bottom: 15px;
}
.otpList input::placeholder{
  padding: 20px;
}
.selectBox {
  border: 1px solid #dbdee9;
  padding: 25px 10px;
  display: flex;
  align-items: flex-start;
  position: relative;
  /* max-height: 9rem; */
}

.selectBox h3 {
  color: #001a5c;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.selectBox p {
  /* line-height: 28px; */
}
.selectBox span {
  display: inline-block;
  margin-right: 20px;
}
.radioActive {
  position: absolute;
  right: 20px;
  /* top: 15px; */
}
.selectDiv {
  background-color: #f1f8fd;
}

.btnBar {
  border-top: 1px solid #efefef;
  padding-top: 25px;
  margin-top: 25px;
  text-align: right;
}
.btnBar button.cancelBtn {
  border: 1px solid #001a5c;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #001a5c;
  padding: 8px 20px;
  background-color: #fff;
  margin-right: 20px;
}
.btnBar button.selectOption {
  border: 1px solid #0094ff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 8px 30px;
  background-color: #0094ff;
}

/*========================My Campaigns Page ===================*/
.editBtn_2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  background-color: #0094ff;
  line-height: 26px;
}
.viewAllBtn {
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 15px;
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.7);
  position: absolute;
  right: 58px;
  top: 6px;
  text-align: center;
  z-index: 1;

}
.viewAllBtn ul {
  /* display: flex; */
  list-style-type: none;
}
.viewAllBtn ul li + li {
  margin-left: 8px;
}
/* .viewAllBtn ul li button {
  background-color: #0094ff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 50px;
} */
.viewAllBtn ul li:last-child button {
  border: 1px solid #ff5548;
  background: #fff;
  color: #ff5548;
}

@-webkit-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.show-box {
  -webkit-animation: SHOW-BOX 1s ease;
  -moz-animation: SHOW-BOX 1s ease;
  -o-animation: SHOW-BOX 1s ease;
  animation: SHOW-BOX 1s ease;
}
/*========================Withdraw Amount Page ===================*/
.avaTitle {
  padding: 50px 0;
  border-bottom: 1px solid #e1e1e1;
}
.avaTitle p {
  font-size: 20px;
  color: #5f718d;
  font-weight: 500;
  margin-bottom: 8px;
}
.avaTitle h1 {
  font-size: 50px;
  color: #001a5c;
  font-weight: 500;
}
.drawAmout {
  font-size: 24px;
  color: #001a5c;
}
.drawAmout svg {
  font-size: 30px;
  margin-right: 10px;
}
.selectBankBox {
  position: relative;
}
.selectBank h2 {
  font-size: 20px;
  color: #5f718d;
  margin-bottom: 20px;
}
.selectBank label {
  width: 100%;
  height: 100%;
}
.selectBank input {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 25px;
  width: 30px;
}
.selectBankBox {
  background: #ffffff;
  padding: 24px;
  border-radius: 15px;
}
.selectBankBox p {
  font-size: 16px;
  color: #5f718d;
  font-weight: 500;
  margin-bottom: 5px;
}
.selectBankBox h3 {
  font-size: 19px;
  color: #001a5c;
  font-weight: 500;
}
.selectBankBox .button {
  border-radius: 50px;
  color: #fff;
  background-color: #0cd7b2;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.selectBankBox button svg {
  font-size: 25px;
  margin-right: 10px;
}
.bank {
  font-size: 20px;
  color: #001a5c;
  font-weight: 500;
  padding-bottom: 30px;
}
.bank img {
  margin-right: 15px;
}
.addNewAccount {
  border: 2px dashed #5f718d;
  border-radius: 15px;
  min-height: 240px;
  width: 400px;
  margin-bottom: 10px;
}
.addNewAccount figure {
  width: 100%;
}
.addNewAccount figcaption {
  font-size: 16px;
  font-weight: 500;
  color: #001a5c;
  margin-top: 20px;
}
.deletBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
/*========================add Funds Page ===================*/
.enterAmout {
  min-height: 400px;
}
.enterAmout label {
  font-size: 20px;
  font-weight: 500;
  color: #5f718d;
  display: block;
  margin-bottom: 15px;
}
.enterAmout input {
  width: 500px;
  border-radius: 50px;
  border: 1px solid #e1e1e1;
  height: 60px;
  padding: 8px 30px;
  font-size: 30px;
  font-weight: 500;
  color: #001a5c;
  text-align: center;
}
.enterAmout input::placeholder {
  color: #001a5c;
}

/*========================All Popus Page ===================*/

.cancelPopup {
  background-color: #fff;
  max-width: 800px;
  padding: 30px;
  position: relative;
}
.cancelPopup h1 {
  font-size: 24px;
  margin-bottom: 10px;
}
.cancelPopup .investAmout {
  color: #5f718d;
  font-size: 16px;
  font-weight: 600;
}
.cancelPopup .investAmout span {
  color: #001a5c;
  font-size: 16px;
  font-weight: 600;
  display: block;
  padding-top: 5px;
}
.cancelPopup label {
  font-size: 16px;
  color: #001a5c;
  margin-bottom: 10px;
  font-weight: 500;
}
.cancelPopup input {
  height: 56px;
  border: 1px solid #dbdee9;
  width: 100%;
  padding: 10px 15px;
  color: #001a5c;
  font-weight: 500;
  font-size: 15px;
}
.popFooter {
  border-top: 1px solid #ccc;
  margin-top: 25px;
  padding-top: 25px;
}
button.closePopup {
  background: none;
  position: absolute;
  right: 20px;
  top: 10px;
}
.infoMsg {
  position: absolute;
  right: 20px;
  top: 20px;
}

.confirmPopup {
  position: absolute;
  width: 470px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;
}
.innerContent {
  padding: 50px 30px;
  background-color: #fff;
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);
}
.confirmPopup p {
  color: #5f718d;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
}
.confirmPopup h2 {
  color: #001a5c;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 500;
  margin: 20px 0px;
}
.continueBtn {
  width: 118px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.refundPopup {
  max-width: 600px;
  padding: 50px 30px;
  background-color: #fff;
  margin-top: 40px;
  text-align: center;
}
.refundPopup p {
  color: #5f718d;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  max-width: 70%;
  margin: 0 auto 20px;
}
.refundPopup h2 {
  color: #001a5c;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 500;
  margin: 20px 0px;
}
.refundPopup .cancelBtn {
  width: 118px;
  height: 40px;
  border: 1px solid #001a5c;
  border-radius: 50px;
  color: #001a5c;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  margin-right: 15px;
}
.refundPopup .refundBtn {
  width: 118px;
  height: 40px;
  border: 1px solid #0094ff;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: #0094ff;
}

.transactionApprovedPopup {
  background-color: #fff;
  max-width: 800px;
  padding: 30px;
  margin-top: 40px;
}
.transactionApprovedPopup h3 {
  color: #001a5c;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 500;
  margin: 20px 0px;
}
.noteMsg h6 {
  font-weight: 500;
  color: #5f718d;
  font-size: 16px;
  margin-bottom: 10px;
}
.noteMsg p {
  font-weight: 500;
  color: #001a5c;
  font-size: 16px;
  line-height: 25px;
}
.noteMsg p a {
  color: #0094ff;
}
.contractBtn {
  border-top: 1px solid #dbdee9;
  margin-top: 25px;
  padding-top: 25px;
}
.contractBtn button {
  width: auto;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  padding: 7px 25px;
}
.contractBtn button svg {
  font-size: 20px;
  margin-right: 10px;
}
.contractBtn button:hover {
  background-color: #001a5c;
}

.accountVerifyPopup {
  padding: 50px 30px;
  background-color: #fff; /*box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);width: 470px;*/
  text-align: center;
  position: relative;
}
.accountVerifyPopup h2 {
  color: #001a5c;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 20px;
  font-weight: 500;
  margin: 20px 0px;
}
.accountVerifyPopup p {
  font-weight: 500;
  color: #5f718d;
  font-size: 16px;
  margin-bottom: 10px;
  width: 75%;
  margin: 0 auto 20px;
}
.accountVerifyPopup span {
  background: #0cd7b2;
  height: 60px;
  width: 60px;
  display: inline-block;
  border-radius: 50%;
  line-height: 50px;
  font-size: 50px;
  color: #fff;
  margin-bottom: 0px;
}

.has-tooltip {
  position: relative;
  cursor: pointer;
  display: inline-block;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.has-tooltip .tooltip {
  color: #001a5c;
  display: block;
  opacity: 0;
  padding: 1em;
  position: absolute;
  font-size: 14px;
  visibility: hidden;
  width: 250px;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  background: #ffffff;
  border: 1px solid #c3e6ff;
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);
}
.has-tooltip .tooltip:before {
  content: " ";
  display: block;
  height: 1em;
  position: absolute;
  width: 100%;
}
.has-tooltip:hover .tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0px) translateY(0px) !important;
  -moz-transform: translateX(0px) translateY(0px) !important;
  -ms-transform: translateX(0px) translateY(0px) !important;
  -o-transform: translateX(0px) translateY(0px) !important;
  transform: translateX(0px) translateY(0px) !important;
}
.has-tooltip.west .tooltip {
  right: 100%;
  left: -1rem;
  bottom: auto;
  top: -2.5em;
  margin-top: 0%;
  /* margin-right: 0.6em; */
  right: 0;
  margin-bottom: auto;
  -webkit-transform: translateX(10px) translateY(0px);
  -moz-transform: translateX(10px) translateY(0px);
  -ms-transform: translateX(10px) translateY(0px);
  -o-transform: translateX(10px) translateY(0px);
  transform: translateX(10px) translateY(0px);
}
.has-tooltip.west .tooltip:before {
  right: -1em;
  height: 100%;
  top: 0;
  width: 1em;
}

.transApproved .modal-header {
  border: none;
  height: auto;
}
.transApproved .modal-body {
  padding: 0 40px 40px;
}

.passwordIcon {
  position: relative;
}
.passwordIcon span {
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
}
.passwordIcon span img {
  width: 25px;
}

/*========================All Popus Page ===================*/

.col-md-6.form-group.mb-3 {
  position: relative;
}

.col-md-6.form-group.mb-3 .form-control {
  padding-right: 50px;
}

.tableData tr#cloneItems > td {
    font-size: 16px !important;
    color: #001a5c;
    font-weight: 500 !important;
    padding: 12px;
    background-color: #c4cccf0f;
}
table.popUpTable > tbody > tr{
  border-bottom: 1px solid #eee;
  font-size: 18px;
}
.custometable{
  color: #fff;
  width: 100%;
  font-weight: 500;
  line-height: 28px;
}

@media all and (max-width: 575px) {
  .listOverview li{
    flex: 0 0 100%;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 16px;
  }

  .lineBg:after{display:none;}
}
/* .navigation ul {
  height: 100vh;
  overflow-y: auto;
} */
.navigation ul.menubarSlide{
  height: 100vh;
  overflow-y: auto;
}

.houseRenoTitle{
  padding: 10px;
}



/*================= News Css 24-11-2024 =======================*/
.socialMedia{display: flex; flex-wrap: wrap;gap:1%;}
.socialMedia li{list-style: none; flex: 0 0 24%; border: 1px solid #ccc; border-radius:7px; padding:0px 4px;display: flex; align-items: center;}
.socialMedia li:first-child svg{width: 22px;}
.socialMedia li input{ font-size: 16px; color: #333; font-weight: 500;transition: all 0.5s;border: none;}
.socialMedia li svg{font-size:50px; margin-right: 10px; width:30px;}
.socialMedia li a:hover{background: #0094ff; color: #fff; border-color: #0094ff;}

@media all and (max-width: 1199px) {
  .reviewList li{font-size: 13px;}
  .walletBalance li{font-size: 15px;}
  }
@media all and (max-width: 991px) {
.reviewList li{font-size: 13px;}
.walletBalance li{font-size: 15px;}
}