@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css);
@import url(../src/components/InvestorDashboard/investorDashboard.css);
@import url(../src/components/Signup/signIn.css);
@import url(../src/components/KycProcess/customForm.css);

/* ============------popUp Section----============= */
body {
  color: #000;
  font-family: " DM sans-serif", sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
label {
  color: #000;
}
.pop-bg {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/***** new change for pop follow steps*****/

.follow_steps-pop {
  position: relative;
  margin: 0px auto;
  max-width: 500px;
  width: 100%;
  padding: 40px 40px 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  z-index: 1002;
  overflow: auto;
}

.follow_steps-pop a {
  text-decoration: none;
  color: #000;
}

.follow_steps-pop h2 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin: 0px 0px 10px;
}

.follow_steps-pop p {
  text-align: center;
  font-size: 14px;
  padding: 10px 10px 24px;
  margin: 0px;
}

/***** new change for pop follow steps end *****/
.pop_content {
  position: relative;
  padding: 77px 50px;
}
.pop_contentRaised2 {
  padding: 0px 20px;
  border-radius: 10px;
  overflow: auto;
}
.pop_contentRaised {
  position: relative;
  margin: 0px auto;
  max-width: 440px;
  width: 100%;
  padding: 77px 50px;
  border-radius: 10px;
  opacity: 1;
  z-index: 1002;
  overflow: auto;
}
.pop_content a {
  text-decoration: none;
  color: #000;
}
.grey-bg {
  background: #f4f5f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 30px 0px;
  margin-bottom: 20px;
}
.pop-img-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 195px;
  border-radius: 10px;
  opacity: 1;
  padding: 10px 0px;
  max-width: 190px;
  margin: 0 auto;
}
.pop-img-box img {
  max-width: 70px;
  margin-top: 20px;
}
.pop-img-box a {
  text-decoration: none;
  color: #000;
}
.pop-img-box-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 10px;
  opacity: 1;
  padding: 9px;
  font-size: 14px;
  text-decoration: none;
  color: #000;
  max-width: 100px;
  margin: 20px auto;
  text-align: center;
}

.pop_content h2 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  margin: 0px 0px 33px;
  color: #373737;
}

.pop_content p {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

.grey-bg p {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 84px;
  right: 60px;
}
.close-buttonpopoUp {
  height: 12px;
  width: 12px;
  cursor: pointer;
}
.close-buttonRaised {
  height: 12px;
  width: 12px;
  cursor: pointer;
}

form#file-upload-button {
  background-color: #000 !important;
  border: none !important;
}

.form-control::-webkit-file-upload-button {
  background: #f4f5f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-right: 5px;
  opacity: 1;
  font-size: 12px;
  color: #000;
  font-weight: 700;
  border: 0px;
  padding: 8px 15px;
}

.grey-bg input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  width: 100%;
  border-radius: 5px;
  height: auto;
  font-size: 12px;
  padding: 10px;
  box-shadow: none;
  opacity: 1;
  margin-bottom: 10px;
  max-width: 320px;
}
.grey-bg select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  height: auto;
  color: #000;
  font-size: 12px;
  height: 40px;
  border-radius: 10px;
}

.grey-bg label {
  margin: 10px 0px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.form-div {
  position: relative;
}
.form-div label {
  padding-left: 4px;
}
.cancel-button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: 10px auto;
  opacity: 1;
  color: #000;
  text-align: center;
  font-size: 18px;
  max-width: 200px;
  padding: 8px 15px;
  text-decoration: none;
}

.creat-button {
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 10px;
  margin: 10px auto;
  opacity: 1;
  color: #fff;
  text-align: center;
  font-size: 18px;
  max-width: 200px;
  padding: 8px 15px;
  text-decoration: none;
}

form .grey-bg a {
  text-decoration: none;
  color: #000;
  margin: 10px auto;
}

/***** pop end*****/

/* ================= LOGIN PAGES CSS ================= */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

.loginPage {
  font-family: "DM Sans", sans-serif;
  color: #5f718d;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.loginPage h3 {
  color: #001a5c;
  font-size: 40px;
}
.loginPage .subLine {
  font-size: 16px;
  color: #5f718d;
}

.loginPage .subLine a,
.loginPage .subLine .linkBtn {
  color: #0094ff;
}
.loginPage input {
  height: 60px;
  border: 2px solid #dbdee9;
  padding: 10px 20px;
  font-size: 16px;
}
.loginPage .submitBtn {
  background: #0094ff;
  border: 1px solid #0094ff;
  height: 60px;
  border-radius: 30px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}
.loginPage .passwordIcon {
  position: relative;
}
.loginPage .passwordIcon span {
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
}
.loginPage .passwordIcon span img {
  width: 25px;
}
.loginPage .orLine {
  text-align: center;
  color: #e0e0e0;
  font-size: 16px;
  position: relative;
}
.loginPage .orLine::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 45%;
  background: #e0e0e0;
  left: 0px;
  top: 10px;
}
.loginPage .orLine::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 45%;
  background: #e0e0e0;
  right: 0px;
  top: 10px;
}

.loginPage.btn:hover {
  box-shadow: 0 3px 6px #e1e1e1;
}

.loginPage .backBtn {
  background: none;
  height: 60px;
  border-radius: 30px;
  color: #001a5c;
  border: 1px solid #001a5c;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
}

.loginPage .loginWithSocal .btn {
  width: 100%;
  border-radius: 10px;
  background: #ccc;
  height: 60px;
  padding: 10px 25px;
  display: flex;
  align-items: center;
}
.loginPage .loginWithSocal .btn img {
  margin-right: 10px;
}
.loginPage .loginWithSocal .loginGoogle {
  background: #fff;
  color: #5f718d;
}
.loginPage .loginWithSocal .loginLinkedIn {
  background: #0c64c5;
  color: #fff;
}

/* Login steps */
.login-screen {
  max-width: 1078px;
  margin: 0 auto;
}
.login-screenRaised {
  margin: 0 auto;
}

/* .login-screen input[type="text"],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"],
    textarea,
    select {
      border: 1px solid #D8D8D8;
      background:#fff;
      border-radius: 10px;
      font-size: 14px;
      height: auto;
      margin: 0;
      outline: 0;
      padding: 15px;
      width: 100%;
      background-color: #FFFFFF;
      color: #8a97a0;
      margin-bottom: 20px; position: relative;
    } */

.check-box-div {
  width: 50%;
  float: left;
  font-size: 15px;
}

.check-box-div span {
  margin-top: 4px;
  font-size: 15px;
  color: #000;
  position: absolute;
  margin-left: 13px;
}
.forgot-div {
  width: 50%;
  float: left;
  text-align: right;
  color: #000;
}
.forgot-div span {
  font-size: 15px;
  color: #000;
}

.check-button {
  border-radius: 3px;
  height: 21px;
  width: 21px;
  border: 1px solid #d8d8d8;
  margin-top: 6px;
}

.eye-icon {
  margin-left: -50px;
  top: 55px;
  cursor: pointer;
  position: absolute;
  font-size: 15px;
  color: #808080;
}

.login-screen label {
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 18px;
}

.login-screen p {
  margin: 0px;
  padding: 20px 0px 0px;
}

.login-screen p span {
  font-size: 16px;
  font-weight: 700;
}

.login-screen p.popNewsLetter {
  text-align: left;
  font-size: 15px;
  line-height: 22px;
  position: relative;
}

.login-screen p.popNewsLetter:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #dfe4ee;
  width: 460px;
  left: 105%;
  top: 82%;
}

.login-screen .popNewsLetter.sendMsg:after {
  position: initial;
  width: 100%;
  margin-left: 15px;
}
.login-screen .popNewsLetter.sendMsg {
  text-align: left;
  font-size: 15px;
  line-height: 22px;
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 0;
  margin-top: 20px;
}

.login-screen p.popsocialMedia {
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  position: relative;
}

.login-screen p.popsocialMedia:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #dfe4ee;
  width: 550px;
  left: 100px;
  top: 76%;
}

.login-screen-button {
  /* UI Properties */
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  border: 0px;
  font-size: 18px;
  padding: 15px 0px;
  color: #fff;
}

/* Login steps End */

/* @import url("./pop.css"); */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: DM Sans;
  /* vertical-align: baseline; */
}

/* Fonts */

/* @font-face {
    font-family: "DMSans-Regular";
    src: url(../fonts/DMSans-Regular.ttf);
}
@font-face {
    font-family: "DMSans-Italic";
    src: url(../fonts/DMSans-Italic.ttf);
}
@font-face {
    font-family: "DMSans-Medium";
    src: url(../fonts/DMSans-Medium.ttf);
}
@font-face {
    font-family: "DMSans-MediumItalic";
    src: url(../fonts/DMSans-MediumItalic.ttf);
}
@font-face {
    font-family: "DMSans-Bold";
    src: url(../fonts/DMSans-Bold.ttf);
}
@font-face {
    font-family: "DMSans-BoldItalic";
    src: url(../fonts/DMSans-BoldItalic.ttf);
} */

:root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "DMSans-Regular", sans-serif;
  --font-secondary: "Poppins", sans-serif;
}

/* Colors */
:root {
  --color-default: #f3faff;
  --color-primary: #008374;
  --color-secondary: #f85a40;
  --color-footer: #001a5c;
  --color-sitemap: #0094ff;
}

/* Smooth scroll behavior */

:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  font-family: var(--font-default);
  overflow-x: hidden;
  /* background-color: var(--color-default); */
  /* background: linear-gradient(126.37deg, rgba(0, 148, 255, 0.2) 11.06%, rgba(217, 217, 217, 0) 102.87%, rgba(217, 217, 217, 0) 102.87%, rgba(255, 255, 255, 0.2) 102.87%); */
}
a {
  /* color: #fff; */
  text-decoration: none;
}

a:hover {
  /* color: #00b6a1; */
  text-decoration: none;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
} */

/* ul,
li {
  list-style: none;
} */

/*--------------------------------------------------------------
    # Sections & Section Header
    --------------------------------------------------------------*/
/* section {
        padding: 0;
        overflow: hidden;
      } */

.sections-bg {
  background-color: #f6f6f6;
}

.section-header {
  text-align: center;
  padding-bottom: 60px;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 60px 0 60px 0;
  min-height: 20vh;
  position: relative;
  background-color: var(--color-primary);
}

.breadcrumbs .page-header h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs .page-header p {
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumbs nav {
  background-color: #f6f6f6;
  padding: 20px 0;
}

.breadcrumbs nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-default);
}

.breadcrumbs nav ol a {
  color: var(--color-primary);
  transition: 0.3s;
}

.breadcrumbs nav ol a:hover {
  text-decoration: underline;
}

.breadcrumbs nav ol li + li {
  padding-left: 10px;
}

.breadcrumbs nav ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: var(--color-secondary);
  content: "/";
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background: var(--color-secondary);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(248, 90, 64, 0.8);
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary)
    transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1.5s linear infinite;
  animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.topbar {
  background: #00796b;
  height: 40px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
}

.topbar .contact-info i {
  font-style: normal;
  color: #fff;
  line-height: 0;
}

.topbar .contact-info i a,
.topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

@media (max-width: 575px) {
  .topbar .contact-info i a,
  .topbar .contact-info i span {
    font-size: 13px;
  }
}

.topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}
.topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}
.topbar .social-links a {
  color: rgba(255, 255, 255, 0.7);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}
.topbar .social-links a:hover {
  color: #fff;
}

.header {
  transition: all 0.5s;
  z-index: 997;
  /* background-color: var(--color-primary); */
}

.header.sticked {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  /* box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1); */
  /* background-color: #f5f7fb;
  background-image: url(../public/Image/heroImage.png);
  background-size: cover;
  background-repeat: no-repeat; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.7) 0.09%,
    rgba(255, 255, 255, 0.25) 99.84%
  );
  backdrop-filter: blur(10px);
  background-image: url(../public/Image/HeroSection.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;

  transition: all 0.5s ease;
}

.header2.sticked {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 70px;
  z-index: 99;
  /* background-image: url(../img/heroImage2.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

.header .logo img {
  /* max-height: 40px; */
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  letter-spacing: 0.8px;
  color: #fff;
  font-family: var(--font-primary);
}

.header .logo h1 span {
  color: #f96f59;
}
header .crtmNevbar {
  padding: 10px 0;
}
header .raiseButton{
background-color: #61FFE3;
text-transform: uppercase;
color:#001A5C;
font-weight: 500;
cursor: pointer;
padding: 10px 20px;
}

header .crtmNevbar ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
}
header .crtmNevbar li + li {
  margin-left: 10px;
}
header .crtmNevbar li a {
  display: block;
}

/* =========== HEADER DROPDOWN MENU =========== */

/*header .userLogin .dropdown .dropdown-menu a {
  font-size: 14px;
  padding: 8px 15px;
} */
header .userLogin .dropdown .dropdown-menu {
  /* width: 285px; */
  padding: 0px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.4);
  border: navajowhite;
  margin-top: 13px;
  margin-left: -231px;
  /* margin-right: -21px;  */
}
header .userLogin .dropdown .dropdown-menu:after {
  content: " ";
  position: absolute;
  right: 10%;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid #fff;
}

header .userLogin .dropdown .dropdown-menu::before {
  bottom: 100%;
  left: 75%;
  /* border: solid transparent; */
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(222, 222, 222, 0);
  border-bottom-color: #dedede;
  border-width: 11px;
  margin-left: -11px;
}
header .userLogin .dropdown {
  width: auto;
}
header .userLogin .dropdown-toggle {
  background: #0094ff;
  border: 0px;
  border-radius: 100%;
  padding: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
}
header .userLogin .dropdown-toggle::after {
  display: none;
}
header .userLogin .dropdown-toggle img {
  /* width: 24px; */
  margin-bottom: 5px;
  height: 44px;
}
header .userLogin .loginSetting {
  display: flex;
  background: #f4f4f4;
  border-radius: 0 0 5px 5px;
  justify-content: space-between;
  width: 300px;
}
.loginSetting > .loginSetting::after {
  content: "";
  top: 96px;
  right: 187px;
  width: 1px;
  position: absolute;
  background-color: #1950be;
  height: 62px;
}
header .userLogin .dropdown .loginSetting a {
  color: #555;
  width: 100%;
  line-height: 27px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
}
header .userLogin .dropdown .loginSetting a:hover {
  color: #000;
  background: #ddd;
  border-radius: 0px;
}
header .userLogin .dropdown .loginSetting img {
  width: 15px;
  margin-bottom: 3px;
}

/* =========== HEADER DROPDOWN MENU =========== */

.raisefund a {
  background: #61ffe3;
  padding: 10px 28px 11px;
  border-radius: 32px;
  color: #001a5c;
  font-weight: 600;
}
.signIn a {
  padding: 7px 22px 10px;
  border-radius: 32px;
  color: #ffffff;
  border: 1px solid #f5f7fb;
  font-weight: 600;
  background-color: #0a2568;
}
.logIn a {
  padding: 7px 22px 10px;
  border-radius: 32px;
  color: #ffffff;
  border: 1px solid #f5f7fb;
  font-weight: 600;
  background-color: #0a2568;
}
.activeSigLog a {
  background: #0094ff;
  padding: 7px 22px 10px;
  border-radius: 32px;
  color: #fff;
  border: 1px solid #0094ff;
  font-weight: 500;
}
.sticked-header-offset {
  margin-top: 70px;
}
section {
  scroll-margin-top: 70px;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.navbar a {
  background-color: red;
}

.navbar a:last-child {
  background-color: transparent;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-family: var(--font-secondary);
  font-size: 15px;
  color: #000;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a img,
.navbar a img:focus {
  max-width: 100px;
  width: 100%;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

/* .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: var(--color-secondary);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  } */

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #000;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 28px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  text-transform: none;
  font-weight: 600;
  color: #006a5d;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: var(--color-secondary);
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (min-width: 1280px) {
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {
  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(0, 131, 116, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-primary);
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover > a {
    color: #fff;
  }
  .navbar .getstarted,
  .navbar .getstarted:focus {
    background: var(--color-primary);
    padding: 8px 20px;
    border-radius: 4px;
    margin: 15px;
    color: #fff;
  }

  .navbar .getstarted:hover,
  .navbar .getstarted:focus:hover {
    color: #fff;
    background: rgba(0, 131, 116, 0.8);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    background-color: #007466;
    border: 1px solid #006459;
  }

  .navbar .dropdown > .dropdown-active,
  .navbar .dropdown .dropdown > .dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    color: rgba(255, 255, 255, 0.6);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    margin-right: 10px;
  }

  .mobile-nav-hide {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(0, 106, 93, 0.8);
    z-index: 9996;
  }
}

/* @media (min-width: 1600px) {
  #heroSection .container {
    max-width: 80%;
  }
} */

/* .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(0, 131, 116, 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  } */

/* .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-primary);
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    transition: 0.3s;
  } */

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: var(--color-primary);
  padding: 8px 20px;
  border-radius: 4px;
  margin: 15px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: rgba(0, 131, 116, 0.8);
}

.navbar .dropdown ul,
.navbar .dropdown .dropdown ul {
  position: static;
  display: none;
  padding: 10px 0;
  margin: 10px 20px;
  transition: all 0.5s ease-in-out;
  background-color: #007466;
  border: 1px solid #006459;
}

.navbar .dropdown > .dropdown-active,
.navbar .dropdown .dropdown > .dropdown-active {
  display: block;
}

.mobile-nav-show {
  color: rgba(255, 255, 255, 0.6);
  font-size: 28px;
  cursor: pointer;
  line-height: 0;
  transition: 0.5s;
  z-index: 9999;
  margin-right: 10px;
}

.mobile-nav-hide {
  color: #fff;
  font-size: 32px;
  cursor: pointer;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 9999;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .navbar {
  right: 0;
}

.mobile-nav-active .navbar:before {
  content: "";
  position: fixed;
  inset: 0;
  background: rgba(0, 106, 93, 0.8);
  z-index: 9996;
}

/* #heroSection video {
  width: 100%;
  height: 200px;
} */

#heroSection {
  /* background-image: url(../public/Image/group41.png); */
  background-image: url(../public/Image/HeroSection.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  /* height: 750px; */
  /* padding: 50px 0 175px; */
  position: relative;
  padding: 50px 0 0px;
}

#heroSection .transparentBg {
  position: absolute;
  bottom: -140px;
  left: 0;
  width: 100%;
  z-index: 1;
}
#heroSection .transparentBg img {
  width: 100%;
}

#heroSection .bnrTextBox {
  position: relative;
  z-index: 9;
}

/* #heroSection::after {
  content: "";
  background: url(../public/Image/banner-left-cloud.png) no-repeat center left;
  background-image: url(../public/Image/banner-left-cloud.png);
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  height: 230px;
  width: 538px;
  bottom: 0px;
  left: 0px;
} */

/* #heroSection::before {
  content: "";
  background: url(../public/Image/banner-right-cloud.png) no-repeat center left;
  background-image: url(../public/Image/banner-right-cloud.png);
  background-repeat: no-repeat;
  background-position: center right;
  position: absolute;
  height: 350px;
  width: 877px;
  bottom: 0px;
  right: 0px;
} */

#heroSection h4 {
  /* font-size: 53px;
  line-height: 55px;
  color: #00195a; */
  color: #fff;
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 116.667% */
  letter-spacing: -1.8px;
}

#heroSection p.weAartner {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  margin-top: 13px;
}

#heroSection2 {
  background-image: url(../public/Image/heroImage2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#heroSection2 h4 {
  font-size: 41px;
  color: var(--color-default);
}
#heroSection2 p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  color: var(--color-default);
}

#heroSection_shariacompilance {
  background-image: url(../public/Image/shariacompilance.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 650px;
}

#heroSection_shariacompilance h4 {
  font-size: 35px;
  color: var(--color-default);
  margin-top: 100px;
  font-weight: 400;
}
#heroSection_shariacompilance p {
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  color: var(--color-default);
  font-weight: 300;
}
.sharia_compliance{
  background-color: #ffff;
  padding: 20px;
  margin-top: -50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.sharia_compliance .platform_sharia{
  font-size: 40px;
  font-weight: 400;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-gifs {
  /* width: 150px;
  height: 150px; */
  object-fit: cover;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.image-gifs.large {
  transform: scale(1.5);
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.heading_sharia-considration{
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
}
.mabrook_description{
  padding: 60px 30px;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
}
.mufti_status{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mufti_name{
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}
.mufti_desig{
  font-size: 16px;
  font-weight: 600;
  color: #0094FF;
  text-transform: uppercase;
}
.mufti_description{
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.mufti_about{
  font-size: 18px;
  font-weight: 400;
  color: #5F718D;
}
#heroSection9 {
  background: url(/public/Image/contactUspage.png) no-repeat center center;
  background-size: cover;
  height: 750px;
  position: relative;
  background-position: top;
}

#heroSection9 .banrBox {
  display: flex;
  align-items: center;
  height: 100%;
}

/* .howToInvest {
  background-image: url(/public/Image/videoSection.png);
  padding-top: 50px;
} */
.howToInvest .howToInvestCont {
  margin-top: 180px;
}
.howToInvest .inviteVideo iframe {
  width: 600px;
  height: 380px;
}
.howToInvest .btn__theme {
  margin-top: 30px;
}
#heroSection10 {
  /* background-image: url(../img/group57.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 605px;
  position: relative;
  background-color: #fff;
}

#heroSection10:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

#heroSection9 h4 {
  font-size: 47px;
  line-height: 55px;
  color: #00195a;
}
#heroSection10 h4 {
  font-size: 30px;
  line-height: 35px;
  color: #00195a;
  padding-bottom: 20px;
}

#heroSection9 p.weAartner {
  font-size: 14px;
  color: #5f718d;
  line-height: 25px;
  margin-top: 13px;
}

#heroSection10 p.weAartner {
  font-size: 14px;
  color: #5f718d;
  line-height: 25px;
  margin-top: 13px;
}
.raiseFunds {
  position: absolute;
  width: 186px;
  height: 44px;
  left: 835px;
  top: 35px;
  background: #61ffe3;
  border-radius: 50px;
  margin-right: 20px;
}

/* ========-------======== */
.card-img-overlay {
  height: 100%;
  z-index: 11;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  background-color: var(--color-footer);
  /* padding: 50px 0; */
  color: white;
}

#footer:after {
  content: "";
  width: 50%;
  height: 200px;
  position: relative;
  background-color: red;
}

.footer .footer-info .logo {
  line-height: 0;
  margin-bottom: 25px;
}

.footer .footer-info .logo img {
  max-height: 40px;
  margin-right: 6px;
}
.footer .footer-info p {
  font-family: var(--font-primary);
  line-height: 25px;
}
.footer .footer-info p.last {
  font-family: var(--font-primary);
  line-height: 25px;
  padding-bottom: 30px;
}
.footer h4 {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  margin-bottom: 30px;
  color: var(--color-sitemap);
}
.privacypolicy {
  display: flex;
  justify-content: space-between;
  max-width: 269px;
  width: 100%;
  font-size: 13px;
  margin-top: 55px;
}
.copyright {
  margin-top: 35px;
}
.footerWidth {
  max-width: 450px;
  width: 100%;
  padding-top: 30px;
}
.email {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

#newsLetter {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
  padding-top: 230px;
}
#Shariaconsidration {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

#newsLetter > div {
  background-color: #9fd7ff;
  /* background: linear-gradient(271.88deg, #80CAFF 2.36%, rgba(128, 202, 255, 0.5) 105.57%); */
}

/*.SubscribetoGet > div {
  background-color: #9fd7ff; 
   background: linear-gradient(271.88deg, #80CAFF 2.36%, rgba(128, 202, 255, 0.5) 105.57%); 
}*/
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 7px !important;
}
.viewmodal h6 {
  font-size: 18px;
}
.react-datepicker-wrapper {
  display: block !important;
}
.SubscribetoGet p {
  margin-top: 20px;
  color: var(--color-footer);
}

.SubscribetoGet h4 {
  font-size: 30px;
  color: #001a5c;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
}

#newsLetter p {
  /* 1440 */
  color: var(--color-footer);
  font-size: 20px;
  font-weight: 500;
}

#newsLetter h4 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -1px;
}

.subScribeBox {
  position: relative;
  display: inline-flex;
}
.subScribeBox input::placeholder {
  color: #fff;
}
.subScribeBox .btn {
  position: absolute;
  right: 10px;
}

.buttonDesign {
  margin-left: -114px;
  border-radius: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 16px;
  width: 100%;
  max-width: 105px;
  font-weight: 600;
  /* line-height: 18px; */
  background-color: #0094ff;
  border: 0px;
}

.inputNewsLetter {
  width: 420px;
  height: 48px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid #001a5b;
  padding-right: 126px;
  border-radius: 100px;
}

.main {
  display: flex;
  /* padding-left: 25px; */
  cursor: pointer;
  align-items: flex-start;
  /* padding-top: 23px; */
}

/* Hide the default checkbox */
input[type="checkbox"] {
  visibility: visible;
}

.main input {
  margin-top: 5px;
}
/* Creating a custom checkbox
based on demand */
.geekmark {
  height: 18px;
  width: 18px;
  background-color: #001a5b;
  border-radius: 4px;
}

.main input:checked ~ .geekmark {
  background-color: #001a5b;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.main input:checked ~ .geekmark::after {
  display: block;
}

.main .geekmark::after {
  left: 7px;
  bottom: 3px;
  width: 5px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.main span {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.5px;
  padding-left: 5px;
  line-height: normal;
}

.inputNewsLetter:active,
.inputNewsLetter:active,
.inputNewsLetter:focus {
  background-color: transparent;
}

.familyUsingMobile:after {
  content: "";
  /* background-image: url(../img/newsLette.png); */
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 87px;
  bottom: 0%;
  width: 100%;
  min-height: 354px;
  max-width: 434px;
}

.coupleUsingMobile {
  display: flex;
  justify-content: center;
  margin-top: -127px;
}

/* .arabManUsingMobile:after {
  content: "";
  background-image: url(../public/Image/Arab-men-using-phone1.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 7px;
  bottom: 0;
  width: 100%;
  max-width: 404px;
  border: 0px solid red;
  top: -31px;
} */

/* .subscribeNewsLette:after {
  content: "";
  background-image: url(../img/middle-eastern-spouses.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 7px;
  bottom: 0;
  width: 100%;
  min-height: 447px;
  max-width: 494px;
  border: 0px solid red;
  top: -31px;
} */

/* .socialMediaIcon:after {
  content: "";
  background-image: url(../img/girl-with-coffe-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 7px;
  bottom: 0;
  width: 100%;
  min-height: 447px;
  max-width: 494px;
  border: 0px solid red;
  top: -31px;
} */

.imageWithSocialIcon {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.imageWithSocialIcon > div {
  margin-right: 20px;
}

.goTo {
  background-color: #001857;
  padding: 20px;
  color: #fff;
  line-height: 22px;
}

.sendButton {
  border-radius: 20px;
}

.browseProject {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.browseProject .card figure {
  position: relative;
}
.browseProject .card figure:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(0, 26, 92, 1) 90%
  );
  z-index: 11;
}
#browseProject .card figure:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(0, 26, 92, 1) 90%
  );
  z-index: 11;
}
.browseProject .card figure .card-img {
  object-fit: fill;
}

.browseProject .card {
  border-radius: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#browseProject .card {
  border-radius: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.browseProject .card-body {
  position: relative;
  transition-delay: 1s;
}
.browseProject .card-body .hoverCont {
  display: none;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px;
  background: #fff;
}
.browseProject .card-body:hover .hoverCont {
  display: block;
}

#browseProject {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

#browseProject .bp p.ss {
  color: #5f718d;
  font-weight: 400;
  position: relative;
}

#browseProject .bp p.all {
  color: #5f718d;
  font-weight: 500;
  font-size: 12px;
}

#browseProject .bp p.ss:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #dbdee9;
  top: 73%;
  right: 0;
  left: 129px;
}

#browseProject h4 {
  color: #001a5b;
  font-size: 39px;
  font-weight: 500;
  margin-top: 18px;
  letter-spacing: -0.1px;
}

.been {
  background-color: var(--bs-btn-hover-bg);
  color: #fff;
}

.card-body p.houeTitle {
  color: #0094ff;
  font-size: 13px;
  font-weight: 500;
}

.card-title {
  font-size: 23px;
  font-weight: 500;
  color: var(--color-footer);
}
/* .card-text {
  color: #5f718d;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 19px;
} */
.card-textBox {
  color: #5f718d;
  font-size: 13px;
  margin-top: 10px;
  /* font-weight: 500; */
  line-height: 19px;
}
.cardBodyHeight {
  height: 530px;
  max-width: 422px;
}

div[class^="box"] {
  width: 100%;
  display: inline-block;
  max-width: 321px;
  height: 5px;
}

.box1 {
  background: linear-gradient(
      to left,
      #ececec 0,
      #ececec 50.3%,
      #0094ff 33.3%,
      #0094ff 66.6%,
      #3fdaec 66.6%,
      #3fdaec
    )
    no-repeat;
  background-size: 100% 5px;
  border-radius: 10px;
}

.box2 {
  background: linear-gradient(
      to left,
      #ececec 0,
      #ececec 40.3%,
      #0094ff 33.3%,
      #0094ff 66.6%,
      #3fdaec 66.6%,
      #3fdaec
    )
    no-repeat;
  background-size: 100% 5px;
  border-radius: 10px;
}

.softcap {
  color: #3fdaec;
  font-size: 14px;
  line-height: 2;
  margin-top: 65px;
  margin-left: 50px;
}

.softcapHover {
  color: #3fdaec;
  font-size: 14px;
  line-height: 15px;
  margin-top: 14px;
  margin-left: 50px;
  padding-bottom: 3px;
}
.hardcapHover {
  display: flex;
  justify-content: space-between;
}

.hardcapHover p.softText {
  font-size: 13px;
  line-height: 9px;
  color: #5f718d !important;
}

.hardcapHover p.softTexts {
  font-size: 13px;
  line-height: 9px;
  color: #5f718d !important;
  text-align: right;
}

.amount {
  font-size: 16px;
  color: #0cd7b2;
  font-weight: 600;
}

.amounts {
  font-size: 16px;
  color: #001a5b;
  font-weight: 600;
  text-align: right;
}

.claender {
  max-width: 20px;
  width: 100%;
}

.figcaption {
  color: #001a5c;
  font-size: 14px;
  padding-left: 9px;
  font-weight: 400;
}

/* hr {
  border-bottom: 2px solid #dbdee9;
  margin: 0;
} */

.ds {
  display: block;
}

.ss {
  display: none;
}

.lineHeight {
  line-height: 2;
}

.ourPartner {
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.containerfluidCustomeSection {
  width: 1300px;
  padding: 0;
  margin: 0 auto;
}

.ourPartner p {
  color: #001a5c;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
.compnayName {
  color: #0094ff !important;

  font-size: 20px !important;
  font-style: normal !important;
}

.partners {
  color: #5f718d !important;
  font-size: 16px !important;
}

.companywithPartner {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  /* padding: 10px 0; */
}

.logoFigCaption {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 0 5px;
  align-items: center;
}
.logoFigCaption img {
  /* max-width: 150px;
  border-radius: 5px;
  height: 70px; */
}
.teslaName {
  width: 100%;
  border: 0px solid red;
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  /* padding-top: 5px; */
  max-width: 290px;
}

.logoFigCaption::after {
  content: "";
  background-color: #e6e6e6;
  position: absolute;
  width: 1px;
  height: 75px;
  top: 0;
  left: -20px;
  right: 0;
}

* {
  box-sizing: border-box;
}

.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  outline: none !important;
}

.flex-item-left {
  background-color: #fff;
  flex: 50%;
  position: relative;
  box-shadow: 0 0 3px #d0d0d0;
  outline: none !important;
}

.flex-item-left:hover {
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);
  cursor: pointer;
}

.flex-item-left:hover::before {
  content: "";
  width: 1%;
  height: 52%;
  position: absolute;
  background-color: #008df3;
  top: 25%;
  left: 0;
}

.ribaIcon {
  display: flex;
  align-items: center;
  padding: 20px 9px;
  width: 100%;
  max-width: 430px;
}

.ribaIconMale {
  display: flex;
  margin-top: -51px;
}

.ribaIcon p {
  color: var(--color-footer);
  font-size: 13px;
  line-height: 21px;
  text-align: left;
  font-weight: 400;
  /* margin-top: 8px; */
}

.ribaIcon img {
  padding: 0px 15px 0px 15px;
}

.flex-item-right {
  background-color: transparent;
  padding: 10px;
  flex: 50%;
}

footer p,
footer a,
footer .copyright {
  font-size: 15px;
  color: #fff;
}

.browse {
  list-style-type: none;
  width: 140px;
  line-height: 27px;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.react-tabs ul.invesTab li:focus-visible {
  outline: none;
}

.free {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.free h5.whyshould {
  text-transform: uppercase;
  color: #0094ff;
  font-weight: 600;
}

.free h4 {
  color: #001a5c;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* padding-bottom: 10px; */
}

.free p {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 22px; */
}

.certified {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.certifieds {
  background-image: url(../public/Image/certifiedImage.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.certiss {
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
}

.certiss::after {
  content: "";
  width: 100%;
  margin-left: 20px;
  background-color: #0047ff;
  height: 1px;
  margin-top: 5px;
  max-width: 830px;
}
.certi {
  padding: 50px 50px 30px;
}
.learnMore_ShariaCompialnce {
  padding: 0px 50px 40px;
}
.weAre {
  font-size: 42px;
  margin-top: 40px;
  line-height: 59px;
  color: #fff;
}

.highyeild {
  max-width: 500px;
  font-size: 16px;
  margin-top: 16px;
  line-height: 26px;
  color: #fff;
}

.inveti {
  font-size: 16px;
  position: relative;
  color: #5f718d;
  font-weight: 500;
  margin-top: -90px;
}

.inveti:after {
  content: "";
  position: absolute;
  width: 100%;
  top: 66%;
  margin-left: 10px;
  background-color: #dbdee9;
  max-width: 991px;
  height: 1px;
}

.userFeddback {
  color: #5f718d;
  font-size: 13px;
  position: relative;
}

.userFeddback:after {
  content: "";
  top: 65%;
  left: 8%;
  position: absolute;
  background-color: #e1e5ee;
  height: 1px;
  max-width: 991px;
  width: 100%;
}

.investingProcess {
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.userSection {
  padding-top: 100px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.howto h4 {
  color: #001a5c;
  font-size: 37px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 60px; */
}
.howtoInvestPara {
  /* padding-right: 10px; */
  max-width: 580px;
  width: 100%;
}
.howto p {
  color: #5f718d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.howto {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.buttonwitharrow {
  display: flex;
  justify-content: space-between;
}

/* ========================= */

/* Let's style the component! __ Start copying from here */
.btn__theme {
  display: flex;
  background-color: #008df3;
  max-width: 358px;
  height: 58px;
  border-radius: 31px;
  transition: background-color 300ms ease, box-shadow 300ms ease;
  animation: btn__scale-down 600ms ease;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.btn__themesvg {
  transform: translateX(24px);
  opacity: 0;
  animation: arrow__slide-ltr 600ms ease-out;
}

.btn__theme:hover {
  offset: 0px, 0px rgba(0, 148, 255, 0.75);
  border-radius: 35px;
  filter: drop-shadow(0px 0px 4px #4444dd);
  cursor: pointer;
}
.btn__content {
  color: rgb(255, 255, 255);
  padding: 0px 20px;
  font-size: 16px;
}
.rightSideImage {
  padding: 0px 20px;
  font-size: 16px;
}

.btn__content span {
  vertical-align: middle;
}

.btn__content svg {
  transition: opacity 600ms ease;
}

.buttonCircular {
  background-color: #008df3;
  border: none;
  color: white;
  padding: 9px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px 2px;
  cursor: pointer;
  border-radius: 40px;
  font-weight: 600;
}

.selection {
  display: flex;
}

.selection h4 {
  font-size: 25px;
  line-height: 26px;
  color: #001a5c;
}

.whoWeAre {
  padding-top: 40px;
  padding-bottom: 60px;
  background-color: #001a5c;
}

.whoWe {
  color: #0094ff !important;
  font-weight: 400;
}

.learnMore {
  display: flex;
}
.learnMoreBorderRadius {
  border-radius: 30px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.hoversection:hover {
  opacity: 0.5;
}

/* ============================================ */
.shapes {
  background-color: #fff;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  height: 526px;
  border-bottom-right-radius: 34px;
  border-bottom-left-radius: 34px;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
}

.kumar {
  margin-top: 31px;
}

.blockSection2 {
  top: -6%;
  border: 0;
  box-shadow: 0px 4px 4px 0px #00000040;
}

/* ===========Hamberg========= */
/* Icon 1 */
#resp-table {
  width: 100%;
  display: table;
}

#resp-table-body {
  display: table-row-group;
}

.resp-table-row {
  display: table-row;
}

.table-body-cell {
  display: table-cell;
  border-right: 1px solid #dddddd;
  padding: 8px;
  line-height: 1.42857143;
}

.table-body-cell::after {
  display: table-cell;
  border-right: 1px solid #dddddd;
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  height: 524px;
  width: 50%;
  position: absolute;
  margin-top: -50px;
}

/*test----*/

.footer-border {
  border-bottom: 1px solid #00227a;
  border-right: 1px solid #00227a;
}

.footer-border-bottom {
  border-bottom: 1px solid #00227a;
}

.footer-border-right {
  border-right: 1px solid #00227a;
}

.padding-footer {
  padding: 35px 50px;
}
.padding-footer_col1 {
  /* padding: 0px 30px; */
}
.padding-footerLast {
  padding: 15px 50px;
}
.padding-footerLast_col1 {
  /* padding: 0px 30px; */
}
.projects {
  color: #5f718d;

  position: relative;
}

.projects::after {
  content: "";
  width: 90%;
  height: 1px;
  background-color: #dbdee9;
  position: absolute;
  margin-left: 10px;
  top: 12px;
}
.browseprojects {
  color: #5f718d;
  position: relative;
}
.browseprojects::after {
  content: "";
  width: 90%;
  height: 1px;
  background-color: #dbdee9;
  position: absolute;
  top: 12px;
  margin-left: 10px;
}

.aboutMetaBrook {
  color: #5f718d;
  position: relative;
}

.aboutMetaBrook:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #dbdee9;
  top: 72%;
  left: 12%;
  max-width: 980px;
}

.alMabrookPara {
  color: #5f718d;
  font-size: 15px;
  line-height: 22px;
  font-weight: 300;
  margin-top: 21px;
}

.all {
  color: #5f718d;
  font-size: 15px;
  padding-bottom: 30px;
  padding-top: 10px;
  letter-spacing: -0.1px;
}

.user h4 {
  color: #001a5b;
  font-size: 38px;
  font-weight: 500;
  margin-top: 18px;
  list-style: 40px;
}

.startInvesting {
  background-color: #0094ff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 100px;
  font-weight: normal;
}

.howItWorks {
  margin-left: 32px;
  color: #fff;
  font-size: 20px;
  line-height: 19px;
  border: 2px solid #fff;
  padding: 10px;
  border-radius: 30px;
}

.startInves {
  align-items: baseline;
  padding-top: 23px;
}

.paginationSection {
  padding-top: 20px;
  padding-bottom: 20px;
   background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  ); 
}
.paginationColor {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.MuiTablePagination-actions {
  display: none;
}

.paginations {
  display: flex;
  justify-content: center;
}

.searchPointBlock {
  padding-top: 35px;
  padding-bottom: 20px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.searchPoint {
  background-color: #fff;
  padding: 1px 50px;
  box-shadow: 0 2px 5px #d7d7d7;
  position: relative;
  margin-top: -80px;
  border: 0px solid red;
}
.imageSearch h4 {
  color: var(--color-footer);
  font-size: 30px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  .dropdown {
    width: 600px;
  }
}

.dropdown__switch:checked + .dropdown__options-filter .dropdown__select {
  transform: scaleY(1);
  z-index: 1;
}

.dropdown__switch:checked + .dropdown__options-filter .dropdown__filter:after {
  transform: rotate(-135deg);
}
.dropdown__filter-selected {
  color: #001a5c;
  font-weight: 400;
}
.dropdown__options-filter {
  width: 100%;
  cursor: pointer;
  margin-top: 22px;
  max-width: 220px;
  border: 1px solid #ced4da;
  border-radius: 31px;
}

.dropdown__filter {
  position: relative;
  display: flex;
  padding: 7px 20px 8px 20px;
  color: #595959;
  font-size: 14px;
  transition: 0.3s;
  list-style-type: none;
}

.dropdown__filter:focus {
  border-bottom: 1px solid #918ff4;
  outline: none;
  /* box-shadow: 0 0 5px 3px #918ff4; */
}

.dropdown__filter::after {
  position: absolute;
  top: 45%;
  right: 20px;
  content: "";
  width: 10px;
  height: 10px;
  border-right: 2px solid #595959;
  border-bottom: 2px solid #595959;
  transform: rotate(45deg) translateX(-45%);
  transition: 0.2s ease-in-out;
}

.dropdown__select {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
  transform: scaleY(0);
  transform-origin: top;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}

.dropdown__select-option {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #d6d6d6;
  transition: 0.3s;
}

.dropdown__select-option:last-of-type {
  border-bottom: 0;
}

.dropdown__select-option:hover {
  background-color: #f9f9f9;
}

/* ============= */

.site-blocks-cover {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.bg-offer {
  background: linear-gradient(rgba(43, 40, 37, 0.9), rgba(43, 40, 37, 0.9)),
    /*  url(../img/offer.jpg)*/ center center no-repeat;
  background-size: cover;
}

.team-item img {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.team-item:after:hover img {
  content: "";
  width: 100px;
  height: 100px;
  top: 0;
  bottom: 0;
  background-color: red;
}

.team-item .team-overlay {
  transition: 0.5s;
  opacity: 0;
  background: linear-gradient(
    360deg,
    #001a5c 12.93%,
    rgba(0, 26, 92, 0) 75.78%
  );
}

.team-item:hover .team-overlay {
  opacity: 1;
}

.testimonial-carousel .owl-dots {
  height: 45px;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  width: 10px;
  height: 25px;
  background: #dddddd;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  height: 45px;
  background: var(--primary);
}

.testimonial-carousel .owl-item .testimonial-item {
  opacity: 0.1;
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  opacity: 1;
}

/* ============== */
.searchIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
}

.sendMessageButton {
  display: flex;
  justify-content: center;
}

.buttonRaidus {
  border-radius: 29px;
  padding: 12px;
  font-weight: 400;
  width: 100%;
}

.borderLessSection {
  border: 0;
}

.manUsingLaptop {
  padding: 100px 0;
  background: url(/public/Image/manUsingLaptop.png) no-repeat bottom right,
    linear-gradient(
      -80.37deg,
      rgba(0, 148, 255, 0.2) 11.06%,
      rgba(217, 217, 217, 0) 102.87%,
      rgba(217, 217, 217, 0) 102.87%,
      rgba(255, 255, 255, 0.2) 102.87%
    );
}

.userNameandImagequestionPost {
  display: flex;
  font-weight: 500;
}
.userNameandImage {
  display: flex;
  justify-content: space-between;
}
.userNameandImageAns {
  display: flex;
  justify-content: space-between;
}
.userName {
  padding-top: 13px;
  padding-left: 29px;
}

.questions {
  font-size: 25px;
  font-weight: 500;
  color: var(--color-footer);
}

.viewMoreSection {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.questionsandAnser {
  padding-top: 40px;
  padding-bottom: 20px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.questionsPara {
  color: #5f718d;
  /* padding-left: 75px;
  padding-right: 30px; */
  font-size: 14px;
}

.questionsNumber {
  background-color: #eff1f4;
  border-radius: 20px;
  color: #5f718d;
  padding: 5px 10px 5px 10px;
}
.answerNumber {
  background-color: #eff1f4;
  border-radius: 20px;
  color: #0094ff;
  padding: 5px 10px 5px 10px;
  font-weight: 600;
}
.closeAnser {
  border-radius: 20px;
  background-color: #001a5c;
}
.closeAnser:hover {
  background-color: #001a5c;
}

.questTex {
  align-items: center;
  display: flex;
}

.lineBootom {
  border-bottom: 1px solid #dbdee9;
}

.textSection {
  display: flex;
  justify-content: space-between;
}

.smalliconwithtext {
  display: flex;
  align-items: center;
}

.paddingRight {
  padding-right: 20px;
}

.imageLeft {
  margin-left: -13px;
}

.userColor {
  color: #001a5c;
  font-weight: 600;
  font-size: 20px;
}

.questionnumber {
  color: #5f718d;
  padding-left: 5px;
}

.users-details-section {
  /* display: flex;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
} */
}

.users-details-section ul {
  margin: 0 -10px 45px;
}
.users-details-section ul li {
  padding: 0 10px;
}

.users-details-section .lguFiu .rec-arrow {
  display: none;
}
/* .users-details-section .lguFiu .rec-carousel-item:first-child .card{margin-left:0px;}
.users-details-section .lguFiu .rec-carousel-item:last-child .card{margin-right:0px;} */

/* .users-details-section .lguFiu .card {
  margin: 5px;
} */

/* .users-details-section > div {
  display: inline-flex;
  flex: 0 0 347px;
  margin-bottom: 20px;
} */
/* .users-details-section > div:not(:last-child){
  margin-right: 20px;  
} */

/* ======CUP====== */
.cup-details-section {
  display: flex;
  margin-left: 131px;
}

.cup-details-section > div {
  display: inline-flex;
  flex: 0 0 325px;
  margin-bottom: 20px;
}

.cup-details-section > div:not(:last-child) {
  margin-right: 20px;
}

/* ======for scroll========== */
/* #boxscroll {
  border-bottom: 2px solid #00F;
} */

#boxframe {
  position: absolute;
  top: 28px;
  left: 420px;
  width: 400px;
  height: 300px;
  overflow: auto;
  border: 2px solid #0f0;
}

.fahadSection {
  align-content: flex-end;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.ceoColor {
  color: var(--color-footer);
  font-weight: 500;
}

.viewMore {
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewMore1 {
  color: #fff;
}
.viewMore1:hover {
  color: #fff;
}
.viewMoreInvestment {
  width: 100%;
  font-weight: 500;
  background-color: #0094ff;
}

/* .paddingLeft {
  padding-left: 110px;
} */

/* ============----------=========== */
.clowled {
  /* background-image: url(../img/newEffect.png); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  z-index: 99999;
  position: relative;
}

/* .parnerLeftSide {
  margin-left: 80px;
  margin-right: 14px;
} */

.offersinterestRight {
  position: absolute;
  margin-top: -80px;
  max-width: 430px;
  width: 100%;
  padding-left: 60px;
  text-align: left;
}

.ribaIcon p.offersPara {
  color: #001a5c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.imgLeftSide {
  height: 54px;
}
.markforestPara {
  color: #fff;
  line-height: 22px;
}
.markForest {
  top: 55%;
}
.sayAbout .markForest {
  height: auto;
  bottom: 0;
  top: auto;
}

/* ==========---------=========== */

.slide:hover > .fahadCEO {
  display: flex;
  background-color: linear-gradient(
    360deg,
    #001a5c 12.93%,
    rgba(0, 26, 92, 0) 75.78%
  );
  cursor: pointer;
}

.fahadCEO {
  background: rgb(1, 27, 93);
  background: linear-gradient(
    0deg,
    rgba(1, 27, 93, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: none;
  justify-content: space-between;
  align-items: end;
  padding: 30px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.LinkDin {
  display: flex;
  align-items: flex-end;
}
.ceoName {
  font-weight: 500;
  font-size: 30px;
  line-height: 50px;
  color: #fff;
}

.ceoDesignation {
  color: #0094ff;
  font-size: 20px;
  font-weight: 500;
}

.fontSizeLinkdin {
  font-size: 30px;
}

.slide {
  position: relative;
  /* padding-top: 3px; */
  background-color: #001a5c;
}
/* 
.slide img {
  margin-bottom: -4px;
} */

.popNewsLetter {
  color: var(--color-footer);
  font-size: 20px;
  font-weight: 500;
}

.pnewsletter {
  display: flex;
}

.newsSubscribe {
  font-size: 25px;
  color: var(--color-footer);
  font-weight: 500;
}

.subscribeNewsLater {
  margin-top: 35px;
}

.borderadius {
  border-radius: 20px;
}

.popsubscribeButton {
  margin: 5px 0 0 -107px;
}

.subscribeNewsInputClass {
  width: 428px;
  border: 0;
  padding-top: 0px;
  background-color: transparent;
  border: 1px solid #001a5c;
  line-height: 45px;
  padding-right: 108px;
  border-radius: 50px;
  padding-left: 13px;
}

.captionSection {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

a.gotocaption {
  color: #fff;
  border-radius: 20px;
  padding: 4px 15px;
}

.dollarImage {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.dollarImage img {
  width: 220px;
}

.feathersOf {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
/* background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
} */

.feathersofMabrook {
  color: #001a5c;
  font-size: 30px;
  font-weight: 500;
  line-height: 25px;
}
/* 
.featherOf {
  padding-left: 115px;
} */

.cupShield {
  max-width: 80px;
}
.cupSection img {
  padding-left: 20px;
}
.cupSection {
  display: flex;
  justify-content: start;
}

.cupBorderBoxSection {
  border: 0;
  height: 100%;
  min-height: 18rem;

  /* box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1); */
  /* border-radius: 25px; */
}

.card-bodyPaddingLeft {
  padding-left: 35px;
  padding-right: 46px;
  padding-bottom: 40px;
}

.mabrookSection {
  background-color: #001a5c;
  padding-top: 40px;
  padding-bottom: 40px;
}

.mabrookSection .nav-tabs {
  border: 0px;
}
.mabrookSection .nav-tabs {
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.mabrookSection .nav-tabs li {
  width: 100%;
  padding: 15px;
}
.mabrookSection .nav-tabs button {
  display: block;
  padding: 30px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0px;
  width: 100%;
  font-size: 20px;
}
.mabrookSection .nav-tabs button.active {
  background: #0094ff;
  color: #fff;
  border-color: #0094ff;
}

.mabrookHeading {
  position: relative;
}

.mabrookHeading::after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #fff;
  width: 100%;
  margin-left: 15px;
  top: 15px;
  max-width: calc(100% - 124px);
}

.meetSharia {
  font-size: 30px;
  line-height: 40px;
  font-weight: 300;
  color: #fff;
}

.mabrookPara {
  font-size: 16px;
  line-height: 25px;
  color: #fff;
}

.customButtonDesign {
  padding: 20px 80px 20px 80px;
  border-radius: 1px;
  background-color: transparent;
  font-weight: 100;
  width: 100%;
}

.customButtonDesign .active {
  background-color: #0d6efd;
}

.ImageWraperSection {
  /* width: 1300px; */
  padding: 0;
  margin: 0 auto;
}

.ImageWraperSection figure img {
  margin-bottom: -4px;
}

.ImageDisplay {
  display: flex;
  justify-content: center;
}

.footerBottom {
  width: 1300px;
  padding: 0;
  margin: 0 auto;
}

.feathuresofMabrook {
  /* width: 1300px; */
  padding: 0;
  margin: 0 auto;
}

.SubscribetoGet {
  width: 1300px;
  padding: 0;
  margin: 0 auto;
}

.imageRotateHeroSect {
  display: flex;
  justify-content: center;
  margin-top: 160px;
}
.imageRotateHeroSect img {
  width: 70%;
}
/* New Css Start From Here */
.upcomingClaender {
  display: flex;
  justify-content: space-between;
}
.claenderDesign {
  background-color: #fff;
  padding: 3px 8px 4px 7px;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  max-width: 150px;
  width: 100%;
  align-items: center;
  z-index: 1;
}
.upcomingText {
  color: #001a5c;
  font-size: 14px;
  font-weight: 700;
}
.cardBodyIconSection {
  display: flex;
}

.cardBodyIconSection .coin {
  padding: 0;
  height: 34px;
  width: 34px;
  line-height: 32px;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.coin {
  /* background-image: url(../public/Image/coin.png); */
  background-repeat: no-repeat;
  background-color: #fff;
  height: 28px;
  width: 22px;
  border-radius: 20px;
  padding: 16px;
  background-position: center;
  margin-right: 10px;
}
.shareIcon {
  background-image: url(../public/Image/shareIcon.png);
  background-repeat: no-repeat;
  background-color: #fff;
  height: 28px;
  width: 22px;
  border-radius: 20px;
  padding: 16px;
  background-position: center;
  margin-right: 10px;
  z-index: 1;
}
.likeIcon {
  /* background-image: url(../public/Image/likeIcon.png); */
  background-repeat: no-repeat;
  background-color: #fff;
  height: 28px;
  width: 22px;
  border-radius: 20px;
  padding: 16px;
  background-position: center;
  z-index: 1;
}

.renovationTop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 50px 30px 30px 30px;
  /* z-index: 1;
  z-index: 11; */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 26, 92, 1) 100%
  );
}

.renovationTop .moreDetail {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 15px 0;
  color: #fff;
  line-height: 22px;
}

.renovationTop .moreDetail h1,
.renovationTop .moreDetail p,
.renovationTop .moreDetail h2 {
  color: #fff;
}

.renovationTop .readMore {
  font-family: "DM Sans", sans-serif;
  color: #0094ff;
  font-weight: 600;
}

.cardTextHeading {
  color: #61ffe3;
  font-weight: 600;
  font-size: 14px;
}
.card-title-heading {
  color: #fff;
  font-size: 29px;
  line-height: 30px;
  font-weight: 500;
}
.textElipses {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  border: 0px solid red;
}
.paraText {
  color: #fff;
}
.card-text {
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
  line-height: 19px;
}
.cardBodyText {
  z-index: 1;
}
.raisedBorder {
  border: 1px solid #0094ff;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  border-radius: 12px;
  margin-top: 20px;
  background-color: #0094ff;
}

.clenderSection {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: baseline;
}
.borderCenterFigcaption {
  position: relative;
}
/* .borderCenterFigcaption:after {
  content: "";
  left: 130px;
  position: absolute;
  top: 4px;
  width: 1px;
  background: #dbdee9;
  height: 22px;
  right: 0;
} */
.investorsDigit {
  font-weight: 700;
}
.readmore {
  color: #00b6a1;
  margin-top: 30px;
  font-size: 14px;
}
.responsive {
  width: 100%;
  max-width: 232px;
  height: 281px;
  margin: 0 auto;
}

/* .myInputSearchImage {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQOSURBVHgBzZg9T9tAGMf/Zzv0BVDdLW2parYu5UXq0I2wdSMjEm9h7ATfAPgENGOHivDWtTBVnQgjUwN8AIKEALVD3ZICoomvz9kkdw52Xkis8pcS5c539i93j5+XA+6oGG4rM2mio+sFmGbRXUyA5+FwG1eFQ9gbNlpUc2DxiSEwJwloSTBuhY7jyNF3BnA2cPLpEJGBuUB8nkYn0LR4BpzmNglYG0xs14PuOYKavTmT2e72ceTpOv1mlphB7YHAezmYx+naAloGi4+S7eifCaD6QRvQkEbByMHO3LQlM2Wi0xkhkhRBJnzXOH+Pi9hC4LyGwDyoLYKylJFZOPdSOPnY+JbEUxa00hYRWRKO7O/CGK4Hpwf2dvcLqJfKzWZxvPYOhW+/0IwKORtne2l094lWwu1jiCNWiuNsfxNNgcXH56Cx0Uqb8RSO1z+gFZ3tbfvgQObxsM/Gn72dsCn+rRRbqBkHSs88rVTDBltXzyYWyc7KL5KNc6M3bEs1X4sZS0oj01YooZhO92P565aJh8WZsKFyxapXi9+zmjL0RiV8osaz163QVZMrpscUelqtKKCETle36WXKXrfITxangoZJMM4TspcvI0rp5AulEkFDPDCLnCIUj320lkWUKhrSVTA+EA52WepX+nKIWqcZCmO4titmudEiEExVZULE4kw+58HVo+rLHpiOXjmBAvMdkAfG2c9KD2cm7oA8MFaUriHEGNsvJbAH5GoeWKwjL7tYoDG2VcLJSgW+bB5YnjyvdHpA598RRCkdSdlgNcDc6wqYw1KIUo6jgm0EDZFgHUZajiVv7F/u9ik+NiUTUAroxyubtcHEdooYWYGjNLjtUCIzplSqIipUQuR3sA6lJWUHyyhEPRlbRDulGYu+1TpXdqlK/gxWpMKPBi7Jyb715rI36KTMs0AZaKuKTyzRnx1VKFP4sbyLhsCEfu/uUM5voRzUhb11vTJR2P+K20iUgI9ff6G9UQxeZMarNdP14CpJZBtXxS2oGQd3a0iqDdcbT4mEoWtM2KrpgzovpHG/a4aeTrDMO17gZG/KvWsXvP4cXQKCHlYi9/J97eZWuG8z5XYaZquAPCiRrj8ZO/CVhmUpRXH9I4KeyRk6LJn1hRAfaCWHR/h5Bo3R+LSb53mrmEGYuGOJEKWhno5W0vS2Dvtcie+ZBFP+3Ljmpja0dfpgJfnUtIQyYp4OaQahhiVmuOZjoBGJxA6Ypsp6AbozRP8q6daGgatIq8OcLEEto6Dn6h4HlBybxpr08dqa4xbVtz8fExLB3ryUdmTft+uCPJ8cIZhyGKIYzW1/JFjtbR3stno6cRC42krJWN/GopBns/lK27VFOgxUSsb/s2Jl9Ywn3GPSMyPbyNHUndA/8ylvbdiatKwAAAAASUVORK5CYII=);
  background-position: 8px 12px;
  background-repeat: no-repeat;
  background-size: 26px;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 41px;
} */

.navBarCertified {
  background-image: url(../public/Image/certifiedImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 139px;
}
.navBarCerPara {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  position: relative;
}

.navBarCerPara::after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #003bd6;
  width: 80%;
  top: 9px;
  margin-left: 10px;
}

.navBarBoxImage {
  padding-left: 20px;
  padding-top: 21px;
}
.wearesharia {
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 11px;
  color: #fff;
}
.hamburger {
  background-image: url(../public/Image/tripleBar.png);
  background-repeat: no-repeat;
}
.navBarParaWithImage {
  display: flex;
  padding-top: 20px;
  align-items: flex-start;
}
.navbarSection {
  color: var(--color-footer);
  font-weight: 600;
  font-size: 13px;
  padding-left: 10px;
  display: flex;
  align-self: center;
}
/* hr {
  border-bottom: 2px solid #dbdee9;
  margin: 0;
} */
.menuBar {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbarSection {
  color: var(--color-footer);
  font-weight: 600;
  font-size: 13px;
  padding-left: 10px;
  display: flex;
  align-self: center;
}

.col1 {
  position: relative;
}
.col1:after {
  content: "";
  position: absolute;
  background-color: #dbdee9;
  height: 95px;
  width: 0.04em;
  right: 0;
  top: 30px;
}

.col2 {
  position: relative;
}
.col2:after {
  content: "";
  position: absolute;
  background-color: #dbdee9;
  height: 95px;
  width: 0.04em;
  right: 0;
  top: 30px;
}
.navBarCertified {
  background-image: url(../public/Image/certifiedImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 139px;
}

.myInputSearchImage {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQOSURBVHgBzZg9T9tAGMf/Zzv0BVDdLW2parYu5UXq0I2wdSMjEm9h7ATfAPgENGOHivDWtTBVnQgjUwN8AIKEALVD3ZICoomvz9kkdw52Xkis8pcS5c539i93j5+XA+6oGG4rM2mio+sFmGbRXUyA5+FwG1eFQ9gbNlpUc2DxiSEwJwloSTBuhY7jyNF3BnA2cPLpEJGBuUB8nkYn0LR4BpzmNglYG0xs14PuOYKavTmT2e72ceTpOv1mlphB7YHAezmYx+naAloGi4+S7eifCaD6QRvQkEbByMHO3LQlM2Wi0xkhkhRBJnzXOH+Pi9hC4LyGwDyoLYKylJFZOPdSOPnY+JbEUxa00hYRWRKO7O/CGK4Hpwf2dvcLqJfKzWZxvPYOhW+/0IwKORtne2l094lWwu1jiCNWiuNsfxNNgcXH56Cx0Uqb8RSO1z+gFZ3tbfvgQObxsM/Gn72dsCn+rRRbqBkHSs88rVTDBltXzyYWyc7KL5KNc6M3bEs1X4sZS0oj01YooZhO92P565aJh8WZsKFyxapXi9+zmjL0RiV8osaz163QVZMrpscUelqtKKCETle36WXKXrfITxangoZJMM4TspcvI0rp5AulEkFDPDCLnCIUj320lkWUKhrSVTA+EA52WepX+nKIWqcZCmO4titmudEiEExVZULE4kw+58HVo+rLHpiOXjmBAvMdkAfG2c9KD2cm7oA8MFaUriHEGNsvJbAH5GoeWKwjL7tYoDG2VcLJSgW+bB5YnjyvdHpA598RRCkdSdlgNcDc6wqYw1KIUo6jgm0EDZFgHUZajiVv7F/u9ik+NiUTUAroxyubtcHEdooYWYGjNLjtUCIzplSqIipUQuR3sA6lJWUHyyhEPRlbRDulGYu+1TpXdqlK/gxWpMKPBi7Jyb715rI36KTMs0AZaKuKTyzRnx1VKFP4sbyLhsCEfu/uUM5voRzUhb11vTJR2P+K20iUgI9ff6G9UQxeZMarNdP14CpJZBtXxS2oGQd3a0iqDdcbT4mEoWtM2KrpgzovpHG/a4aeTrDMO17gZG/KvWsXvP4cXQKCHlYi9/J97eZWuG8z5XYaZquAPCiRrj8ZO/CVhmUpRXH9I4KeyRk6LJn1hRAfaCWHR/h5Bo3R+LSb53mrmEGYuGOJEKWhno5W0vS2Dvtcie+ZBFP+3Ljmpja0dfpgJfnUtIQyYp4OaQahhiVmuOZjoBGJxA6Ypsp6AbozRP8q6daGgatIq8OcLEEto6Dn6h4HlBybxpr08dqa4xbVtz8fExLB3ryUdmTft+uCPJ8cIZhyGKIYzW1/JFjtbR3stno6cRC42krJWN/GopBns/lK27VFOgxUSsb/s2Jl9Ywn3GPSMyPbyNHUndA/8ylvbdiatKwAAAAASUVORK5CYII=);
  background-position: 8px 12px;
  background-repeat: no-repeat;
  background-size: 26px;
  width: 100%;
  font-size: 16px;
  padding: 12px 20px 12px 41px;
}
.menuBarExpand {
  position: absolute;
  z-index: 11111;
  width: 100%;
  background: #fff;
  padding: 10px;
}
.read-more-less--more,
.read-more-less--less {
  color: rgb(86, 133, 198);
}
.freeImageTop {
  position: relative;
  top: 1px;
}
.freeImage {
  max-height: 274px;
  height: auto;
  border: 0px solid red;
}
.freeParaHeading {
  max-width: 500px;
  width: 100%;
}

.inputSearchBorder {
  border: 0;
  font-size: 24px;
  border-radius: 0;
  color: #5f718d;
  font-weight: 400;
  border-right: 1px solid #dbdee9;
}

.sendMessage {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
  padding-top: 20px;
  padding-bottom: 20px;
}

.socalBox {
  margin-top: -180px;
}

.socalBox h3 {
  font-size: 30px;
  color: #001a5c;
  margin-bottom: 80px;
}

.sendMessageBox {
  padding: 20px;
}
.sendMessageBox h5 {
  font-size: 28px;
}
.sendMessageBox p {
  font-size: 16px;
  line-height: 28px;
  height: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.sendMessageBox a.btn {
  margin-top: 50px;
}

/* ============ terms and condition start ============== */
.termsConditionsheading {
  color: #000;
  font-size: 30px;
  margin-top: 110px;
}
.privacyPolicyHeading {
  color: #000;
  font-size: 30px;
  margin-top: 100px;
}
.welcomeAlM {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.paratermsCondition {
  color: #000;
  line-height: 25px;
}
.cookies {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.licence {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.hyperLink {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
ul.termsCondtion {
  list-style-type: disc;
  color: #000;
  margin-left: 35px;
  margin-top: 20px;
}
.addressALM {
  color: #000;
  font-size: 15px;
  line-height: 22px;
}
.sharOppertunity {
  font-size: 16px;
  color: #001a5c;
  font-weight: 600;
  line-height: 20px;
}
.modelPopup {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.sharIconSocial {
  border: 1px solid #dbdee9;
  border-radius: 20px;
  /* padding: 8px; */
  display: flex;
  color: #000;
  align-items: center;
  /* padding-left: 20px; */
}
.sharIconSocial > button {
  margin-right: 9px;
  cursor: pointer;
  /* margin-right: 20px; */
}
.sharIconSocial > img:nth-child(0) {
  margin-right: 0px;
}
.socialColor {
  color: #001a5b;
  font-weight: 500;
  padding-left: 10px;
  font-size: 13px;
}
.points {
  cursor: pointer;
}
.dollarSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dollarIconDesc {
  font-size: 12px;
  color: #5f718d;
  font-weight: 400;
  line-height: 20px;
  padding-right: 11px;
}
.imageIconCardbody {
  width: 100%;
  max-width: 50px;
}
.mabrookToken {
  font-size: 16px;
  color: #001a5c;
  font-weight: 600;
  line-height: 20px;

  max-width: 140px;
  width: 100%;
}
.mabrookHeadinginpopo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailSection {
  margin-top: 12px;
}
.totalSupply {
  color: #203770;
  font-weight: 500;
  margin-top: 5px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.popViewMore {
  width: 100%;
}
.popSectionDolarIconCardDetails {
  width: 300px;
}
.popupSectionDolarIconCardDetails {
  position: absolute;
  z-index: 1;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  margin: 46px -197px;
  padding: 20px;
}

.popupSectionDolarIcon {
  /* width: 200px; */
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 39px;
  padding: 10px 15px;
  right: 8%;
}

.popupSectionDolarIcon-details {
  width: 200px;
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 39px;
  right: -87%;
}
.popupSectionDolarIcon-deta {
  width: 245px;
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  margin-top: 7px;
  padding: 10px 15px;
  right: 0;
}

.popupSectionDolarIconPopup {
  margin: 10px -197px;
}
.pricePoint {
  font-weight: 700;
}
.sharOppertunityIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.circle-container {
  position: relative;
  width: 40em;
  height: 40em;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
  margin: 0 auto;
  border: solid 0px tomato;
}
.circle-container > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6em;
  height: 6em;
  margin: -3em;
}

.circle-container > :nth-of-type(1) {
  transform: rotate(0deg) translate(0) rotate(0deg);
}
.circle-container > :nth-of-type(2) {
  transform: rotate(-61deg) translate(12.5em) rotate(61deg);
}
.circle-container > :nth-of-type(3) {
  transform: rotate(0deg) translate(12.5em) rotate(0deg);
}
.circle-container > :nth-of-type(4) {
  transform: rotate(59deg) translate(12.5em) rotate(-58deg);
}
.circle-container > :nth-of-type(5) {
  transform: rotate(120deg) translate(12.5em) rotate(-121deg);
}
.circle-container > :nth-of-type(6) {
  transform: rotate(180deg) translate(12.5em) rotate(-180deg);
}
.circle-container > :nth-of-type(7) {
  transform: rotate(239deg) translate(12.5em) rotate(-240deg);
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.imgFadeinFadeout {
  -webkit-animation: flickerAnimation 2.1s infinite;
  -moz-animation: flickerAnimation 2.1s infinite;
  -o-animation: flickerAnimation 2.1s infinite;
  animation: flickerAnimation 2.1s infinite;
}

/* ========= faq section ========== */
.content {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  height: 0;
}

.expand {
  height: auto;
  padding-top: 20px;
  /* padding: 20px; */
}
.colapse {
  height: 0;
  padding: 0 20px;
}
.openCloseBorderRadius {
  border-radius: 20px;
  height: 40px;
  justify-content: space-between;
}
.topSpace {
  margin-top: 20px;
}

/* ==========*****========= */

#teamBanner {
  background-image: url(../public/Image/termsOfUse.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 655px;
}
#teamBanner h4 {
  font-size: 40px;
  color: #001a5c;
}
#teamBanner p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 200px;
  color: #001a5c;
}
.teamBanner1 {
  top: 361px;
  position: absolute;
}

.termsBnanerSection {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

#privacyBanner {
  background-image: url(../public/Image/privacypolicyHeader.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 655px;
}
#privacyBanner h4 {
  font-size: 40px;
  color: #001a5c;
}
#privacyBanner p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 200px;
  color: #001a5c;
}
.privacyBanner1 {
  top: 361px;
  position: absolute;
}
.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}
.embla__button__svg {
  width: 100%;
  height: 100%;
}
.embla__button--prev {
  left: 27px;
}
.embla__button--next {
  right: 27px;
}

.embla__progress {
  position: relative;
  background-color: #f4f4f4;
  margin-top: 20px;
  max-width: 270px;
  width: calc(100% - 40px);
  height: 4px;
  overflow: hidden;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
}
.embla__progress__bar {
  position: absolute;
  background-color: #1bcacd;
  width: 100%;
  top: 0;
  bottom: 0;
  left: -100%;
}
.innerPageBanner.blogPage {
  background: url(/public/Image/blog-banner-2.png) no-repeat center center;
}
#heroSection11.whitePaperBnr {
  background: url(/public/Image/white-paper.png) no-repeat center center;
  display: flex;
  align-items: center;
  background-size: cover;
}
#heroSection11.whitePaperBnr .btn__theme h3 {
  color: #fff;
}
#heroSection11.whitePaperBnr .blogBanrCont {
  margin-bottom: 50px;
}
.innerPageBanner {
  display: flex;
  align-items: center;
}

/* >>>>>>>>>>> WhitePaper para <<<<<<<<<*/
#heroSection11 {
  /* background-image: url(../img/group57.png); */
  background-size: cover;
  height: 750px;
  position: relative;
}

#heroSection11 h2 {
  font-size: 20px;
  line-height: 15px;
  color: #0085ff;
  font-family: "DM Sans", sans-serif;
  padding-bottom: 30px;
}

#heroSection11 h4 {
  font-size: 50px;
  line-height: normal;
  color: #00195a;
}

#heroSection11 p.weAartner {
  font-size: 16px;
  color: #5f718d;
  line-height: 25px;
  margin-top: 13px;
}
.privacyPolicySection {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}

.whitePaperPara {
  color: #5f718d;
  padding-right: 30px;
  padding-top: 20px;
  font-size: 14px;
  padding-bottom: 20px;
}
.whitePaperName {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
/* >>>>>>>>>>>>>>>>> Blog <<<<<<<<<<<<<<<<<*/
.blogCardHeader {
  color: #001a5b;
  font-size: 24px;
  margin-top: 30px;
  letter-spacing: -0.1px;
}
.blogSection {
  padding-top: 100px;
  padding-bottom: 20px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
#blogSection p {
  color: #5f718d;
  font-size: 18px;
  padding: 20px 0;
  /* letter-spacing: -0.1px; */
}
.readpost {
  font-size: 16px;
  color: #0094ff;
  padding-bottom: 20px;
  font-weight: 600;
}
.readpost:hover {
  cursor: pointer;
}
.inputSearch {
  border: 0;
  border-radius: 0;
}
.searchItemBlogCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dbdee9;
  line-height: 60px;
}
.searchResultPara {
  color: #001a5c;
  font-size: 17px;
  font-weight: 400;
}
.imageSearchResult {
  height: 30px;
  cursor: pointer;
}
input:focus {
  outline: none !important;
}

.blogDetail {
  position: relative;
  font-family: "DM Sans", sans-serif;
}
.blogDetail .bannerImg {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.blogDetail .blogContent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  font-family: "DM Sans", sans-serif;
}
.blogDetail:after {
  content: "";
  position: absolute;
  width: 90%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgb(1, 146, 252);
  background: linear-gradient(
    90deg,
    rgba(1, 146, 252, 1) 20%,
    rgba(0, 212, 255, 0) 70%
  );
}

.blogContent .backBtn {
  display: inline-flex;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 10px 15px;
  align-items: center;
  font-weight: 600;
}

.blogContent h2 {
  font-size: 20px;
  color: #fff;
}
.blogContent h4 {
  font-size: 40px;
  line-height: 43px;
  color: #fff;
  font-weight: 400;
}
.blogContent p {
  font-size: 16px;
  font-weight: 400;
}

.blogContent .blogerPic {
  display: flex;
  align-items: center;
}
.blogContent .blogerPic img {
  height: 64px;
  width: 64px;
  border-radius: 100%;
  background: #fff;
  padding: 8px;
  margin-right: 15px;
}
.blogContent .blogerPic p {
  font-size: 20px;
}
.custome-blog_mobile{
  margin-top: 50px;
}
.blogContent .blogerPic span {
  font-size: 16px;
  color: rgb(97, 255, 227);
}

.blogDtlCont {
  font-family: "DM Sans", sans-serif;
  background: linear-gradient(-80.37deg, rgba(0, 148, 255, 0.2) 11.06%, rgba(217, 217, 217, 0) 102.87%, rgba(217, 217, 217, 0) 102.87%, rgba(255, 255, 255, 0.2) 102.87%);
}
.blogDtlCont h2 {
  font-size: 24px;
  color: #001a5c;
}
.blogDtlCont p {
  font-size: 18px;
  color: #5f718d;
  font-weight: 300;
}

.faqAcordion {
  font-family: "DM Sans", sans-serif;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.faqAcordion h3 {
  font-size: 25px;
  color: #001a5c;
}

.faqAcordion .accordion {
  background: none;
}
.faqAcordion .accordion .accordion-item {
  border: 0px;
  padding: 0 50px;
}
.faqAcordion .accordion .accordion-item + .accordion-item {
  margin-top: 30px;
}
.faqAcordion .accordion-header button {
  background: none;
  font-size: 30px;
  padding: 20px 0;
  color: #001a5c;
  display: flex;
  align-items: center;
}
/* Rotate */
/* .accordion-collapse.collapse.show {
  transform: rotate(360deg);
  transition: all 1s;
  background-color: yellow;
}
.accordion-collapse.collapse {
  transform: rotate(0deg);
  background-color: yellow;
} */

.faqAcordion .accordion-header button .arrowBtn {
  transform: rotate(180deg);
  transition: all 0.5s;
}
.faqAcordion .accordion-header button.collapsed .arrowBtn {
  transform: rotate(0deg);
}
.faqAcordion .accordion-header button::after {
  display: none;
}
.faqAcordion .accordion-header button > span {
  margin-left: auto;
  margin-right: 25px;
}
.faqAcordion .accordion-header button:focus {
  outline: none;
  box-shadow: none;
}

.faqAcordion .accordion-body {
  padding: 20px 0;
}

.faqAcordion .seeHow {
  color: #0094ff;
  font-size: 18px;
  font-weight: 500;
}
.faqAcordion .faqHelp {
  display: inline-flex;
  align-items: center;
  color: #001a5c;
  padding: 7px 20px 8px;
  border: 1px solid #dbdee9;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
}
.faqAcordion .faqHelp img {
  width: 25px;
  margin-top: 2px;
  margin-right: 5px;
}

.faqAcordion .seeHow {
  color: #0094ff;
  font-size: 18px;
  font-weight: 500;
}
.faqAcordion .faqHelp {
  display: inline-flex;
  align-items: center;
  color: #001a5c;
  padding: 7px 20px 8px;
  border: 1px solid #dbdee9;
  border-radius: 30px;
  font-weight: 500;
  font-size: 16px;
}
.faqAcordion .faqHelp img {
  width: 25px;
  margin-top: 2px;
  margin-right: 5px;
}
#faqBanner {
  background-image: url(../public/Image/faqBanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 750px;
  position: relative;
}
#faqBanner:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
#faqBanner h4 {
  font-size: 40px;
  color: #001a5c;
}
.login-section {
  background: linear-gradient(
      278.54deg,
      rgba(0, 148, 255, 0.2) 0%,
      rgba(0, 148, 255, 0) 100%
    ),
    #ffffff;
  padding-left: 40px;
  padding-top: 15px;
}
.login-sectionRaised {
  background: linear-gradient(
      278.54deg,
      rgba(0, 148, 255, 0.2) 0%,
      rgba(0, 148, 255, 0) 100%
    ),
    #ffffff;
  padding-left: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-right: 10px;
  border-radius: 10px;
}
.arabManUsingMobile {
  margin-top: -94px;
}
.popNewsLetter {
  color: var(--color-footer);
  font-size: 20px;
  font-weight: 500;
}
.subscribeNewsLette {
  margin-top: -71px;
  margin-right: 0px;
}
.socialMediaIcon {
  margin-top: -71px;
}
.slick {
  margin: 0 auto;
  width: 80%;
}
.cardSliderToFix {
  padding: 50px 10px;
}
/* .slick-slide {
  padding: 100px 10px;
}  */

.slick-prev:before {
  content: "" !important;
  background-image: url(../public/Image/leftArrow.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.slick-next:before {
  content: "" !important;
  background-image: url(../public/Image/rightArrow1.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
 
}
.slick-dots li button:before {
  color: gray;
}
/* .slick-dots {
  bottom: -50px !important;
} */
.howOuld {
  font-size: 21px;
  top: 594px;
  position: absolute;
}
.raised {
  cursor: pointer;
}
.investInvestor {
  display: flex;
  justify-content: space-between;
}
.buttonRaised {
  width: 100%;
}
.bid-pop-div {
  position: relative;
  text-align: left;
  margin-bottom: 20px;
}
.bid-pop-div p {
  color: #000000;
  padding: 0px;
  margin: 0px;
  text-align: left;
  line-height: 25px;
}
.bid-pop-div p:first-child {
  color: #98999d !important;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 6px;
}
.bid-pop-div p span {
  color: #3c97f2;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}
.bid-details {
  display: flex;
  margin: 0px;
  padding: 0px 20px;
  width: 100%;
  flex-wrap: wrap;
  width: 100%;
}
.bid-details p {
  text-align: left;
  font-size: 14px;
  color: #000;
  padding: 0px;
  line-height: 32px;
  width: 67%;
  margin: 0px;
  padding-left: 16px;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
}
.bid-details span {
  font-weight: 600;
  text-align: right;
  width: 30%;
  font-size: 14px;
  color: #373737;
  font-weight: 600;
}
.buttonPopUp {
  display: flex;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.makanOffer {
  color: #000000;
  text-align: center;
  padding-bottom: 10px;
  font-size: 20px;
}
.modalWidth {
  max-width: 1080px !important;
  margin: 0 auto;
}
/* Image Rotate */
.raiseFundForm {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
  box-shadow: 0px 5px 40px rgba(167, 199, 222, 0.3);
  border-radius: 10px;
  padding: 20px;
  margin-top: 70px;
}
label.htmlFor {
  padding-bottom: 5px;
  padding-top: 20px;
  font-size: 13px;
  text-align: left;
}
label.htmlFor2 {
  padding-bottom: 5px;
  padding-top: 0px;
  font-size: 13px;
}
.pleadge {
  margin-top: 20px;
}
.pleadgeSection {
  text-align: center;
  color: #001a5c;
  font-weight: 600;
  font-size: 20px;
  position: relative;
}
.pleadgeSection::after {
  content: "";
  position: absolute;
  background-color: green;
  height: 5px;
  text-align: center;
  width: 70px;
  bottom: 0;
  top: 28px;
  left: 0;
  right: 0;
  margin: 0 auto;
  line-height: 32px;
}
/* Input Value Change */

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}
.ic1 {
  margin-top: 40px;
}
.ic2 {
  margin-top: 30px;
}
.customeinput {
  /* background-color: #303245; */
  border-radius: 12px;
  border: 1;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}
.cut {
  background-color: #15172b;
  border-radius: 5px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}
.cut-short {
  width: 100px;
}
.customeinput:focus ~ .cut,
.customeinput:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}
.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}
.customeinput:focus ~ .placeholder,
.customeinput:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}
.customeinput:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}
.customeinput:focus ~ .placeholder {
  color: #dc2f55;
}
/* .satya{
  border: 1px solid red;
} */

/* NEW CSS FOR CRAUSAL */

.carousel-indicators .carousel-indicator {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
.carousel-indicators .carousel-indicator.active {
  opacity: 1;
}
.carddetails {
  background-color: #001a5c;
}
.houseRenovationTop {
  padding-top: 60px;
}
.houseRenovation {
  font-size: 46px;
  line-height: 49px;
  color: #fff;
}

.houseRenovationTop .eQxrcj {
  height: 15px;
  width: 100%;
  margin: 0;
}

.houseRenovationTop .gOktwN {
  background: #0094ff;
  height: 100%;
}
.houseRenovationTop .dDNZdl {
  background: #fff;
  border-radius: 0 25px 25px 0;
}
.houseRenovationTop .eBrFEh {
  background: #0cd7b2;
  border-radius: 25px 0px 0px 25px;
}
.houseRenovationPara {
  font-size: 16px;
  line-height: 26px;
  /* max-width: 330px; */
  width: 100%;
  margin-top: 13px;
  color: #fff;
}

.mabrookTokenButton {
  display: flex;
  background: white;
  border-radius: 30px;
  align-items: center;
  color: #001a5c;
  font-weight: 500;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  font-size: 14px;
  /* width: 150px; */
  height: 50px;
  justify-content: center;
  padding: 10px;
}
.paddingFromRight {
  padding-left: 6px;
  text-transform: uppercase;
}
.cardDetailButton {
  display: flex;
}
.investmentButton {
  padding-left: 30px;
  padding-right: 30px;
  /* box-shadow: 0px 0px 50px rgba(0, 148, 255, 0.5); */
}
.investmentButton:hover {
  box-shadow: 0px 0px 50px rgba(0, 148, 255, 0.5);
}
.softCapHardCap {
  display: flex;
  justify-content: space-between;
}
.daysCounting {
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: 38px;
  margin: 0 5px 30px;
  color: #fff;
  width: 200px;
  height: 50px;
}

.daysCounting .timeLeft {
  width: 220px;
}

.toolTip {
  position: relative;
  cursor: pointer;
}

.toolTip .icon {
  position: absolute;
  right: 0px;
  top: -20px;
}
.toolTip .icon img {
  width: 18px;
}

.imageWithInvestmentIcon {
  position: relative;
}
.imageWithInvestmentIcon > img {
  margin-right: 50px;
}
.daysCountingFAQ {
  display: flex;
  flex-wrap: wrap;
}

.daysCountingFAQ > .daysCounting svg {
  color: #fff;
}
.daysCountingButton {
  display: flex;
  justify-content: space-between;
}
.daysCountingButton > .daysCounting {
  margin-right: 15px;
}
.daysCounting p {
  padding-left: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
.daysCounting span {
  padding-left: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.mileMstoneHeading {
  color: #001857;
  font-weight: 500;
  font-size: 18px;
}
.dateButton {
  border: 1px solid #dbdee9;
  padding: 5px;
  width: 100%;
  max-width: 120px;
  border-radius: 20px;
}
.datewithYear {
  color: #001a5c !important;
  font-weight: 400;
}

.clanderImagePara {
  position: relative;
  padding-right: 40px;
  text-align: center;
  width: 150px;
  border-right: 1px solid #dbdee9;
  flex: 0 0 18%;
}
.paraTextoverview_2 {
  flex: 0 0 82%;
}
.img2 img {
  width: 80px;
}
.clanderImagePara::after {
  height: 100%;
  content: "";
  position: absolute;
  background-color: #dfc7d1;
  left: 98%;
  top: 0;
  width: 0.09em;
  display: none;
}
.paraTextoverview {
  padding-left: 30px;
}
.imageWithQuestion {
  display: flex;
  align-items: center;
  width: 81%;
}
.faqSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imageCountingNumber {
  display: flex;
  align-items: center;
}
.imageCounting {
  padding-right: 20px;
}
.faq {
  background-color: #fff;
  padding: 15px;
}
.faqUestions {
  font-weight: 400;
  padding-left: 17px;
}
.faqNumber {
  font-size: 21px;
  padding-left: 3px;
}
.briefFolder {
  display: flex;
  justify-content: space-between;
}
.briefPresention {
  padding: 10px;
  text-align: center;
  border-radius: 30px;
  background-color: #e4d9e4;
  font-weight: 600;
  color: #001a5c;
  font-size: 16px;
}
.pdf-downloader {
  display: flex;
  align-items: center;
}
.presentionPdf {
  padding-left: 25px;
  font-weight: 400;
  color: #001a5c !important;
}
.mbSize {
  font-weight: 400;
  color: #001a5c !important;
  font-weight: 600;
}
.presentionDownloader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.downLoadAllfiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.downLoadAllfilesradius {
  border-radius: 25px;
  width: 224px;
  height: 50px;
  font-size: 19px;
  font-weight: 600;
}
.fileWithImage {
  display: flex;
  align-items: center;
}
.downloadFiles {
  color: #001a5c;
  font-weight: 600;
  padding-left: 5px;
}
.dateFiles {
  color: #001a5c;
  font-weight: 600;
}
.briefPresentionTop {
  background: #fff;
  padding: 20px;
}
.boxShadowFileDownLoad {
  padding: 33px;
}
.TockenPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameTocken {
  font-weight: 400;
  color: #001a5c !important;
}
.priceTocken {
  font-weight: 600;
  color: #001a5c !important;
}

/* .TockenPriceDetails{margin-top:50px;} */

.ticketTeamBox img {
  object-fit: cover;

  width: 100%;
  padding: 10px;
}

.tockenImage {
  width: 100%;
  max-width: 25px;
  height: auto;
}
.softCapFAQ {
  font-size: 16px;
  color: #fff;
}
.hardCapFAQ {
  text-align: right;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
.softCapFAQTokenMoney {
  color: #0cd7b2;
  font-size: 24px;
  font-weight: 500;
}
.neapolitan {
  background: #0094ff;
  position: relative;
  height: 10px;
  border-radius: 10px;
}
.neapolitan:before {
  content: "";
  background: #0cd7b2;
  width: 30%;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.neapolitan:after {
  content: "";
  background: #fff;
  width: 30%;
  height: 10px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
}
.paymentSection {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}

/* NEW CSS FOR TAB */

.detailTab {
  color: #000;
  padding: 40px 0;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.detailTab ul.tabBox {
  padding: 0px 0 40px;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  flex-wrap: nowrap;
  overflow-x: auto;
}
/* .detailTab ul.tabBox li {
} */
.detailTab ul.tabBox li button {
  background: none;
  color: #5f718d;
  border: 0px;
  border-radius: 30px;
  font-size: 20px;
  padding: 10px 25px;
  margin: 0px;
}
.detailTab ul.tabBox li button:hover,
.detailTab ul.tabBox li button.active {
  border: 0px;
  color: #fff;
  background: #0094ff;
  margin: 0px;
}
.detailTab .tab-content {
  padding-top: 30px;
}

.detailTab h3 {
  color: #001a5c;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detailTab h4 {
  color: #001a5c;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.detailTab p {
  font-size: 18px;
  color: #5f718d;
}

.tabOverView .imgWfull {
  width: 100%;
}
/* .tabOverView p {
  line-height: 30px;
} */

.token {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  font-family: "DM Sans", sans-serif;
  position: relative;
}
.token:before {
  width: 1px;
  height: 100%;
  background: #dfdfdf;
  content: "";
  position: absolute;
  top: 0;
  left: -27px;
}
.sliderMain .slick-next {
  right: 40px;
}

.sliderMain .slick-prev {
  left: 10px;
}
.sliderMain .slick-prev::before{
  font-family: none;
  border: 2px solid #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  display: block;
  font-size: 25px;
  font-weight: bold;
  background-color: #001857;
  /* background-color: transparent; */
   /* background-image: url(../public/Image/leftArrow.png);  */
}
.sliderMain .slick-next::before {
  font-family: none;
  border: 2px solid #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  display: block;
  font-size: 25px;
  font-weight: bold;
  background-color: #001857;

}
/* .slick-prev:before {
  content: '' !important;
  background-image: url(../public/Image/leftArrow.png);
}
 .slick-next:before{
  content: '' !important;
  background-image: url(../public/Image/rightArrow1.png);
  background-repeat: no-repeat;
} */


.sliderMain .slick-prev,
.sliderMain .slick-next {
  z-index: 99;
}
.token h3 {
  font-size: 22px;
}
.token p {
  font-size: 16px;
}
.token hr {
  opacity: 0.1;
}
.token .blueTitl {
  color: #0094ff;
  font-size: 13px;
  text-transform: uppercase;
}
.token .coin {
  height: auto;
  width: auto;
  text-align: center;
}
.token .coin img {
  height: 100px;
  width: 100px;
}
.token .moreInfo {
  background: #0094ff;
  color: #fff;
  border-radius: 30px;
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 0;
}
.token .mbr {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  background: #001a5c;
  padding: 10px 15px;
  color: #fff;
}
.token .mbr img {
  height: 40px;
  margin-right: 10px;
}

.tabOverView .imgWfull {
  width: 100%;
}
.tabOverView p {
  line-height: 30px;
  font-weight: normal;
}
.tab-content section .col-lg-9,
.tab-content section .col-md-9 {
  padding-right: 40px;
}

/* .token {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  font-family: "DM Sans", sans-serif;
} */
.token h3 {
  font-size: 22px;
}
.token p {
  font-size: 16px;
}
.token hr {
  opacity: 0.1;
}
.token .blueTitl {
  color: #0094ff;
  font-size: 13px;
  text-transform: uppercase;
}
.token .coin {
  height: auto;
  width: auto;
  text-align: center;
}

.token .moreInfo {
  background: #0094ff;
  color: #fff;
  border-radius: 30px;
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 500;
  padding: 10px 0;
}
.token .mbr {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  background: #001a5c;
  padding: 10px 15px;
  color: #fff;
}
.token .mbr img {
  height: 40px;
  margin-right: 10px;
}

.tabUpdate h3 > span.date {
  font-size: 20px;
  color: #5f718d;
}
.tabUpdate p {
  color: #5f718d;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 30px;
}
.tabUpdate .updateBox {
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
}
.tabUpdate .updateBox hr {
  opacity: 0.2;
}

.updateBox2 {
  background: #fff;
  padding: 40px;
  margin-bottom: 20px;
}
.updateBox2 hr {
  opacity: 0.2;
}

.colepsBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.colepsBtns button {
  background: none;
  border: 0px;
}
.colepsBtns button.btnLike img {
  width: 30px;
  margin-right: 8px;
}
.colepsBtns button.btnLike {
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #dbdee9;
  padding: 10px 15px 8px;
  border-radius: 30px;
  width: 150px;
  text-align: left;
}

.colepsBtns button.arrowBtn {
  background: #0094ff;
  width: 40px;
  height: 40px;
  line-height: 38px;
  border-radius: 100%;
}
.colepsBtns .arrowBtn.active {
  background: #001a5c;
}
.colepsBtns button.arrowBtn img {
  margin-bottom: 4px;
  transform: rotate(180deg);
  transition: all 0.2s;
}
.colepsBtns .arrowBtn.active img {
  transform: rotate(0deg);
}

.teamBox {
  position: relative;
}
.teamBox img {
  width: 100%;
}
.teamBox h3 {
  color: #fff;
  margin-bottom: 10px;
}
.teamBox p {
  color: #0094ff;
}
.teamBox .hoverValue {
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    0deg,
    rgba(1, 27, 93, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.teamBox:hover .hoverValue {
  opacity: 1;
}

.proSetting h2 {
  font-size: 30px;
  color: #0094ff;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: underline;
}

.proSetting,
.proSetting a {
  color: #000;
}
.proSetting .leftMenu {
  list-style: none;
  transition: all 1s;
}
.proSetting .leftMenu li {
  margin-top: 10px;
}
.proSetting .leftMenu a {
  color: #011b5d;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  font-family: "DM Sans", sans-serif;
  display: block;
  transition: all 1s;
}

.proSetting .leftMenu a:hover {
  color: #0094ff;
  font-weight: 600;
}
.proSetting .settingsBox {
  box-shadow: 0 0 5px #555;
  padding: 30px;
}
.proSetting .settingsBox h3 {
  text-align: center;
  font-size: 25px;
  color: #999;
}
.proSetting .settingsBox h5 {
  color: #555;
  font-weight: 500;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
}
.proSetting .settingsBox p {
  color: #555;
  font-size: 14px;
}

.leftBtn {
  position: relative;
}
.leftBtn .form-control {
  padding-right: 65px;
}
.leftBtn .btnRight {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  background: #0094ff;
  color: #fff;
  border-radius: 0 5px 5px 0;
  height: 100%;
  padding: 0 15px;
}

.teamBox {
  position: relative;
}
.teamBox img {
  width: 100%;
}
.teamBox h3 {
  color: #fff;
  margin-bottom: 10px;
}
.teamBox p {
  color: #0094ff;
}
.teamBox .hoverValue {
  position: absolute;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: none;
  align-items: end;
  background: linear-gradient(
    0deg,
    rgba(1, 27, 93, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.teamBox:hover .hoverValue {
  display: flex;
}
.askDetails {
  font-size: 25px;
  color: #001a5c;
  font-weight: 600;
}
.iplanFor {
  font-size: 18px;
  color: #001a5c;
  font-weight: 600;
}
.InvestingRaiseCapital {
  display: flex;
}

.bannerThum {
  background: #001a5c;
  padding-top: 0px;
}
.bannerThum .imageThumbnil {
  height: 60px;
  width: 100px;
  object-fit: fill;
}
.bannerThum .videoThumbnail {
  height: 60px;
  width: 100px;
  object-fit: fill;
  position: relative;
}

.bannerThum .slick-track {
  display: flex;
}
.bannerThum .slick-track .slick-slide {
  /* max-width:200px; */
  padding: 0 3px;
  /* width: 182px !important; */
  padding-top: 25px;
}
.sliderMain .slick-track img {
  height: 750px;
  object-fit: cover;
  width: 100%;
  /* width: fit-content;  */
  margin: 0 auto;
}

.bannerThum .slick-track .slick-current img {
  border: 1px solid #61ffe3;
}
.bannerThum .slick-track .slick-current::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: solid #61ffe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  content: "";
  position: absolute;
  top: 0px;
  margin: 0px 43px;
  z-index: 999;
}
.ql-container.ql-snow {
  background: #345470eb !important;
  color: #fff !important;
}
span.ql-formats > button[type="button"].ql-image {
  display: none;
}
.newFAQ {
  display: flex;
  justify-content: space-between;
}
.handleLikeIcon {
  display: flex;
  align-items: center;
}
.newFAQChildSection {
  display: flex;
  align-items: center;
}
.headingFAQ {
  font-size: 18px;
  color: #001a5c;
  font-weight: 400;
}
.dropdownFAQ {
  cursor: pointer;
  padding-left: 20px;
}
/* =========---------======== */
#kycCanada {
  background-image: url(../public/Image/image-asset.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 655px;
}
#kycCanada h4 {
  font-size: 20px;
  color: #001a5c;
  font-weight: 500;
}
#kycCanada h1 {
  font-size: 30px;
  color: #001a5c;
  font-weight: 500;
}
#kycCanada p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 200px;
  color: #001a5c;
}
.kycCanada1 {
  top: 361px;
  position: absolute;
}
.headingKYC {
  font-size: 25px;
  font-size: 500;
}
.kycList {
  list-style-type: disc !important;
  padding-left: 18px;
}
.hide {
  display: none;
}
.myDIV:hover + .hide {
  display: block;
  color: red;
}
ul.invesTab {
  list-style-type: none;
}
.wallet-connect-inner {
  position: relative;
  text-align: center;
  color: #000;
}
.wallet-connect-inner h5 {
  font-size: 18px;
  margin: 50px 0px 10px;
  padding: 0px;
  font-weight: 500;
}
.wallet-connect-inner h5 a {
  color: #3c97f2 !important;
}
.wallet-connect-inner p {
  font-size: 14px;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.wallet-connect-grey {
  max-width: 280px;
  margin: 20px auto auto;
  background-color: #f4f5f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.wallet-connect-grey a {
  padding: 12px;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
}
.wallet-connect-grey a img {
  padding-right: 10px;
}
.wallet-connect-grey a p {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  color: #162c5b;
  width: 70%;
  text-align: left !important;
}
.wallet-connect-grey a span {
  font-size: 16px;
  color: #162c5b;
}
ul.invesTab {
  list-style-type: none;
}
.align-item-center {
  align-items: center;
}
.userKYC {
  display: none;
}
.userDetails:hover + .userKYC {
  display: block;
  color: red;
}
.previousNextPage {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25px;
}
.previousNextPage > button {
  margin-right: 10px;
}
.progressbarparentClass {
  display: flex;
  align-items: center;
}
.offcanvas.offcanvas-end {
  height: auto;
  bottom: auto;
}
.emailValidatin {
  display: flex;
  flex-direction: column;
  max-width: 432px;
}

.slider {
  margin-top: 28px;
  color: #0cd7b2;
  position: relative;
  font-size: 13px;
}
.slider::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 2px;
  margin-left: 50px;
  margin-top: -24px;
  background: transparent !important;
}

/* .react-tabs {
  display: flex;
  margin-left: 36px;
  width: 470px;
  height: 400px;
  border: 1px solid #3c3e43;
  color: white;
  background: #1c1d1f;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab--selected {
  background: #1c1d1f;
  border-color: #1c1d1f;
  border-left: 4px solid #6eb800;
  color: white;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
} */

.panel-content {
  text-align: center;
}
.pTab {
  font-family: sans-serif;
  background: #1c1d1f;
  color: white;
  height: 100vh;
  box-sizing: border-box;
}

.typeFile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.typeFile input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: #e9f1ff;
  border: 2px dashed #000;
}
.answerPara p {
  font-size: 15px;
  padding-left: 10px;
  line-height: 26px !important;
}
.seeHowto {
  padding-left: 10px;
}
.seeHowto p {
  color: #0094ff;
}
.thisfaqHelpdme {
  border-radius: 34px;
  border: 1px solid #dbdee9;
  padding: 10px;
  cursor: pointer;
  width: max-content;
}
.thisfaqHelpdme > img {
  margin-right: 10px;
}

.custom-input {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.country-code {
  border-right: 1px solid #ccc;
}

.phone-number {
  flex-grow: 1;
}
.intl-tel-input {
  display: flex !important;
}

/* ============== */
.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

tr.toggleButton {
  border-bottom: 1px solid #eee;
}
tr.toggleButton > td {
  background-color: #f1f0f0;
}

tr.borderLine {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}
.downLoadBtn li button.satya {
  background-color: #001a5c !important;
}
.imgText {
  border-radius: 10px;
}
.signupGirlImg {
  position: relative;
}
/* .tickSucess {
  position: absolute;
  top: 361px;
  z-index: 1;
} */
/* .tickSucess img {
  width: 80%;
} */
/* Animation While Login */
@keyframes slideInFromLeft {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
.tickSucess {
  animation: slideInFromLeft 5s linear infinite;
}
.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
  text-align: center;
  display: inline-block;
}
/* 
.Verify-otp-div {
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 40px 0px 10px; 
} */

.Verify-otp-div input[type="text"] {
  /* width: 75px; */
  float: left;
  margin: 0px 0px;
  line-height: 90px;
  margin-bottom: 20px;
  height: 64px;
  text-align: center;
  /* color: #000 !important; */
  font-size: 30px !important;
  font-weight: 350;
}

.Verify-otp-div input[type="number"] {
  line-height: 50px;
  text-align: center;
  color: #000 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

/* .logIn a:hover{
  color: #fff !important;
}
.signIn a:hover{
  color: #000 !important;
}
  */
header .userLogin .dropdown .loginSetting a ::after {
  background-color: red;
}
.socialIcon {
  padding-left: 30px;
}
ul.truested-contact-persone > li {
  margin-left: 30px;
}
.disabled-links a {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
p[role="slider"] {
  color: #fff !important;
  font-weight: 400;
  text-transform: uppercase;
}
.otpSectionStart {
  display: flex;
  align-items: center;
}
.form-div.otpSectionStart > .Didntrecieve {
  margin-right: 50px;
}

.enlarged-bg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.enlarged-img {
  width: 100%;
  height: 90%;
  object-fit: contain;
  /* background: #ddd; */
}

.expand-btn.minimize-btn {
  bottom: auto;
  top: 20px;
  right: 30px;
  background: #000;
}

.expand-btn.minimize-btn img {
  width: 30px;
  height: 30px;
}

.expand-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: #00000052 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dsblBtn {
  width: 99px;
  height: 40px;
  background: #d9d8d8;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
}

.blueBtn {
  width: 99px;
  height: 40px;
  background: #0094ff;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border: none;
}
.MabrrokToken_Heading {
  font-size: 30px;
  color: #001a5c;
}

.print-url {
  display: none; /* Hide the link by default */
}

@media print {
  .print-url {
    display: inline !important; /* Show the link during print */
  }
}
.HeadingSharia {
  font-size: 40px;
  color: #001a5c;
  font-weight: 400;
}
.HeadingSharia_subheading{
  font-size: 18px;
  color: #0094FF;
  font-weight: 700;
}
.HeadingSharia_subheading_one{
  font-size: 18px;
  color: #001A5C;
  font-weight: 700;
}
.circleSharia {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  padding: 4px;
  background: #008ff6;
  color: #fff;
  text-align: center;
  font: 13px Arial, sans-serif;
  position: absolute;
  margin-left: -30px;
}

#Shariaconsidration ul {
  list-style: none; /* Remove default bullets */
  line-height: 22px;
}

#Shariaconsidration ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #008ff6; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 0.7em; /* Also needed for space (tweak if needed) */
  margin-left: 0em; /* Also needed for space (tweak if needed) */
  font-size: 30px;
}
.shariacompilanceblank {
  position: relative;
  z-index: 1;
  margin-top: -47px;
}
.blankImg {
  height: 300px;
  width: -webkit-fill-available;
}
#Emailsection {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.EmailLogo {
  display: flex;
  justify-content: center;
}
.salespost {
  font-size: 26px;
  color: #001a5c;
  font-weight: 500;
  margin-bottom: 5px;
}
p.salespostpara {
  color: #5f718d;
}
p.salespostparaitalic {
  color: #5f718d;
  font-style: italic;
}
.MabrookEmailSection {
  background-color: #001a5c;
  padding: 10px;
}
.hRDxIG {
  background: #ececec !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
iframe > #vf-chat-input--clmor83120002356cfajwpjta {
  background-color: red !important;
  z-index: 111;
  padding: 10px;
}
.sc-aXZVg.eQxrcj {
  width: 100% !important;
  margin: 0px 0% !important;
}
.sc-beqWaB.fvIkJC {
  width: 100% !important;
  margin: 0px 0% !important;
}
.sc-beqWaB.fvIkJC {
  width: 100% !important;
  margin: 0px 0% !important;
}
.eBEbVS {
  width: 100% !important;
  margin: 0px 1% !important;
}
.lpojws {
  background-color: #0094ff !important;
}
.jZoipo {
  background-color: #0cd7b2 !important;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

#btn-back-to-top {
  background-color: #0094ff;
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: #fff;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  z-index: 9999;
}

/* .form-select {
  border-radius: 50px;
  border: 1px solid #dbdee9;
  background: #fff;
} */

.option {
  padding: 20px;
  border-bottom: 1px #dbdee9 solid;
}
.borderbottomClass {
  border: 1px solid #dbdee9 !important;
}
.footerContainer {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0px 0px 20px;
}
.footerContainer_last {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0px 0px 20px;
}
.footerContainer_footerLast {
  max-width: 1170px;
  margin: -55px auto auto;
  padding-top: 11px;
}

.responsiveTable {
  overflow-x: auto;
}
.searchButton {
  margin-top: 37px;
}
.memberIDSection {
  display: flex;
  align-items: center;
}
.memberIDSectionTop {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  list-style-type: none;
}
.loginSection {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.loginSectionName {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #fff;
  background-color: #0094ff;
}
.loginSectionNames {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
.loginSectionEmail {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.dahsboardsetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboardlayout {
  border-radius: 10px;
  background: #f0f9ff;
  width: 85px;
  /* height: 85px; */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* line-height: 26px; */
  padding-top: 13px;
  padding-bottom: 10px;
  text-align: center;
}
.dashboardlayout > a > p {
  font-size: 14px;
}
.dashboardLogo {
  width: 52px;
  height: 52px;
}
.kycDetails {
  height: 53px;
  border-radius: 50px;
  background: #0094ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: unset !important;
  background-color: unset !important;
}

.finalProject {
  max-width: fit-content;
  margin: 0 auto;
  padding-top: 21px;
}
/*
.react-tabs__tab.react-tabs__tab--selected {
   content: "";
    width: 1%;
    height: 52%;
    position: relative;
    background-color: #008df3;
    top: 25%;
    left: 0;
}

 */

::-webkit-scrollbar {
  width: 5px;
}
/* body::-webkit-scrollbar-track {
  background: #001a5c;
} */
/* body::-webkit-scrollbar-thumb {
  background: #888;
}  */

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #3e57b1;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb {
  background: #3e57b1;
  border-radius: 5px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #3e57b1;
  width: 0%;
}

.downloadPDF {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
}
.blogContent .blogerPic {
  color: #fff;
}
.blogContent p {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
  font-weight: 400;
}
/* .slick-prev{
  left: 11px !important;
} */
/* .slick-next{
  right: 11px !important;
} */
.synqSection {
  display: flex;
  justify-content: space-between;
}
.countryName {
  color: #0094ff;
  font-weight: 500;
}
.sucessful {
  color: #0cd7b2;
  font-weight: 500;
  background: #f0fffd;
  padding: 6px 1pc;
  border-radius: 10px;
}
.fail {
  color: #ff5548;
  font-weight: 500;
  background: #ffefed;
  padding: 6px 1pc;
  border-radius: 10px;
}

.selectOTP {
  margin-top: 5px;
}
.lastRefresh {
  display: flex;
  align-items: center;
}
.lastRefreshwithDate {
  padding-left: 10px;
}
.lastRefreshColor {
  color: #5f718d;
}
.lastRefreshDateColor {
  color: #001a5c;
  font-weight: 600;
}

.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
  border: 1px solid #eee;
  padding: 10px 10px;
  width: 100%;
  display: flex;
  max-width: 460px;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid #0094ff;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  background: rebeccapurple;
  box-shadow: inset 0px 0px 0px 4px #fff;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  background: #0094ff;
  box-shadow: inset 0px 0px 0px 1px #fff;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; /* focus style */
}
.pdfFileSection {
  display: flex;
  justify-content: space-between;
}
.seprationBitweencolumn {
  display: grid;
  flex: 50%;
  grid-template-columns: 1fr 0.1fr;
}
.checkedItem {
  display: flex;
  justify-content: space-between;
}
.ModalSectionOnMobile {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.c-ivtLaR-ftPdvq-withChat-false > .c-PJLV {
  bottom: 100px !important;
}
.mAabrookTokenPopup {
  width: 200px;
  /* margin-top: -152px; */
  /* margin-left: 9px; */
  z-index: 11111;
  background: #fff;
  padding: 10px;
  font-size: 9px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.mAabrookTokenPopup > h3 {
  font-size: 18px !important;
}
.mAabrookTokenPopup > p {
  font-size: 14px !important;
  line-height: 17px;
  margin-top: 6px;
}
.readMoreSectionPopup {
  display: flex;
  justify-content: center;
}
.mAabrookTokenPopupImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.totalSuply {
  font-size: 13px;
  color: #001857;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 23px;
}
.roundImageLogo {
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.tooltip-s {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-s .tooltiptext {
  visibility: hidden;
  width: 120px;
  /* background-color: #555;
  color: #fff; */
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* .tooltip-s .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
} */

.tooltip-s:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.carddetailSections {
  height: 75px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
}
.webCam {
  border: 2px solid #eee;
  text-align: center;
  padding: 10px;
}
.kycFonts {
  font-size: smaller;
}
.DailyTransactionLimit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CareteriaselectAccount {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.exclaimetryIcon {
  font-size: 2em;
  color: #a2a6b2;
}
.takeaSelfi {
  font-size: 20px;
  font-weight: 600;
}
.cameraScreenpara {
  font-size: 14px;
  color: #a9aeb5;
  font-weight: 400;
}
.removeSunglass {
  font-size: 13px;
}
.changemyAccountType {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.basedonyour {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.CrossIcon {
  margin-top: -70px;
  border-radius: 5px;
  margin-left: -24px;
  cursor: pointer;
}
.selfiCaptured {
  border-radius: 5px;
}
.SelfiMode {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selficaptureButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.readyForKYCgirl {
  max-width: 300px;
}
.ReadyFORKYC {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 60px;  166.667% 
  letter-spacing: -1.8px; */
  color: #001a5c;
  margin-bottom: 10px;
}
.completingKYC {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #5f718d;

  /* line-height: 16px; */
}
.tocomplete {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: -1.2px;
}

.pointList {
  padding: 0;
  list-style: none;
}
.pointList li {
  font-size: 16px;
  line-height: 22px;
  color: #5f718d;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
}
.pointList li::before {
  width: 8px;
  height: 8px;
  background: #0094ff;
  border-radius: 50%;
  left: 0;
  top: 8px;
  content: "";
  position: absolute;
}
.kycIagree {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.imageDetailSection {
  display: flex;
  justify-content: space-between;
  max-width: 307px;
}
.FinancialDocuments {
  border: 1px solid #0094ff;
  border-radius: 10px;
  text-align: center;
  width: 96px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.FinancialDocumentscolor {
  color: #0094ff;
  font-size: 12px;
  line-height: 12px;
  margin: 9px;
}
.girlsIcon1 {
  width: 40px;
}
.notetouser {
  background: #e6f5ff;
  display: flex;
  color: #0094ff;
  align-items: center;
  max-width: 307px;
  border-radius: 10px;
  padding: 10px;
}
ul li.bulletsection::before {
  content: "\2022";
  color: #0094ff;
  font-weight: bold;
  display: inline-block;
  width: 0.7em;
  margin-left: -0.7em;
  font-size: 21px;
}
.bulletsection {
  font-size: 12px;
}
.welcomeback {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 200% */
  letter-spacing: -1.5px;
}
.pleaseCountinuue {
  color: #5f718d;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.estimateTime {
  color: #001a5c;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}
.welcomebackCountinue {
  display: flex;
  justify-content: space-between;
}
.clockwithtimer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.completedForm {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 7px;
  border-radius: 20px;
}
.CompletedForms {
  color: #001a5c;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.tick-circle {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: rgba(97, 255, 226, 0.2);
  width: 110px;
  height: 30px;
  justify-content: center;
}
.progress-circle {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: rgba(255, 208, 40, 0.2);
  width: 110px;
  height: 30px;
  justify-content: center;
}

.CompletedAction {
  color: #0cd7b2;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}
.ProgressAction {
  color: #ffc328;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}
.buttonView {
  width: 104px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50px;
  /* background: #0094ff; */
  margin-bottom: 3px;
  line-height: 30px;
}
.viewword {
  color: #fff;
  text-align: center;
  font-size: small;
  border-radius: 50px;
  width: 100px;
}
.resumeKYC {
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    hsla(0, 0%, 85%, 0) 102.87%,
    hsla(0, 0%, 85%, 0) 0,
    hsla(0, 0%, 100%, 0.2) 0
  );
  padding: 10px;
}
.Howlongis {
  color: #0094ff;
  font-family: DM Sans;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: 28px; /* 155.556% */
}
.addmoreSection {
  border: 1px solid #eee;
  padding: 10px;
}
li.bulletList::marker {
  color: #003bd6;
  font-weight: bold;
  display: inline-block;
  width: 0.7em;
  margin-left: -0.7em;
  font-size: 14px;
  line-height: normal;
}
.mobilebanner {
  max-width: 300px;
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.bulletList {
  font-size: 12px;
}
.closeSavepopup {
  display: flex;
  justify-content: flex-end;
  max-width: 447px;
}
.viewMoreButton {
  border-radius: 20px;
  font-size: 12px;
}
.InvestorAccountTypeBodySection {
  display: flex;
  justify-content: space-between;
}
.InvestorAccountType {
  font-size: 20px;
  font-weight: 600;
  color: #00195a;
}
.BasedOnyourIncome {
  font-size: 13px;
  font-weight: 600;
  color: #00195a;
  line-height: 16px;
}
.Foryouraccounttype {
  font-size: 13px;
  color: #5f718d;
}
.DailyTransactionLimit {
  border: 1px solid #eee;
  padding: 10px;
}
.DailyTransactionLimitpara {
  font-size: 13px;
  color: #00195a;
}
.numberValue {
  color: #00195a;
  font-weight: 600;
}
.nextpreviusButtonpoup {
  display: flex;
  justify-content: space-between;
}
.nextpreviusButtonpoups {
  display: flex;
  justify-content: flex-end;
}
.DailyTransactionLimitDot {
  font-size: 12px;
  background-color: #0094ff;
  display: inline-block;
  padding: 1px;
  border-radius: 10px;
  height: 6px;
  line-height: 6px;
  margin-right: 7px;
}
/* Multistep Progress Bar */
.multiStepForm {
  display: flex;
  background: transparent;
  /* max-width: 411px;
  margin: 0 170px; */
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.multiStepForm::after {
  content: "";
  width: 100%;
  height: 2px;
  background: #ccc;
  position: absolute;
  top: 17px;
  z-index: -1;
}

.steps {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}
.step {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #9db1d1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
/* .active{
  border: 3px solid #CEF7F0;
} */
.step.active {
  background-color: #0cd7b2;
  color: #fff;
  box-shadow: 0px 0px 0px 6px #b3f7ea;
}

.step::before {
  height: 0.1em;
  width: 100%;
  content: "";
  position: absolute;
  margin-top: 0px;
  left: 0;
  background: #9db1d1;
  display: none;
}
.step::after {
  height: 0.1em;
  width: 100%;
  content: "";
  position: absolute;
  margin-top: 0px;
  left: 0;
  background: #9db1d1;
  display: none;
}
.PersonalInformationsSection {
  max-width: 180px;
  margin: 0px 160px;
  text-align: center;
}

.PersonalInformations {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: -1.2px;
}
.PersonalInformation {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: -1.2px;
}
/* .AboutCountryList{
  color: #0094FF;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; 
} */
.multiStepProgressForm {
  padding-top: 0px;
  padding-bottom: 0px;
  background: linear-gradient(
    -80.37deg,
    rgba(0, 148, 255, 0.2) 11.06%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(217, 217, 217, 0) 102.87%,
    rgba(255, 255, 255, 0.2) 102.87%
  );
}
.nextPreviousButton {
  /* margin: 0 179px;
  max-width: 419px; */
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-bottom: 18px;
  border-top: 1px solid #dbdee9;
}

/* =======---====== */

.uploadDocument {
  display: flex;
  align-items: center;
  padding-left: 10px;
  justify-content: center;
  font-size: 14px;
}
.uploadDocument p {
  padding-left: 10px;
  color: #0094ff;
  font-weight: 500;
}
.uploadDocumentDropBox {
  background-color: #e6f5ff;
  padding: 5px;
  border: 2px dashed #0094ff;
  margin-top: 9px;
}

#myInput {
  /* background-image: url(https://cdn1.iconfinder.com/data/icons/hawcons/32/698627-icon-111-search-512.png); */
  background-repeat: no-repeat;
  background-position: 7px 6px;
  background-size: 20px;
  width: 100%;
  font-size: 16px;
  padding: 4px 13px 4px 13px;
}
.documentDisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploadTypeImage {
  display: flex;
  align-items: center;
}
.documentOne {
  font-size: 13px;
  color: #00227a;
  font-weight: 600;
}
.documentOneName {
  font-size: 12px;
  color: #5f718d;
}
.NameOfUploadedDocument {
  display: flex;
  align-items: center;
}
.uploaded {
  font-size: 12px;
  font-weight: 500;
  color: #0cd7b2;
}
.NameOfUploadedDocument img {
  width: 15px;
  cursor: pointer;
}

.slick-slider.sliderMain::after {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 7%,
    rgba(255, 255, 255, 0) 24%,
    rgba(255, 255, 255, 0) 72%,
    rgba(0, 26, 92, 1) 100%
  );
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  z-index: -1;
  /* display: none; */
}

.measure {
  width: 100%;
}

.businessReviewForm {
  padding: 50px 0 50px 50px;
}
.manHolding {
  position: relative;
  display: flex;
  align-items: center;
}
.manHolding img {
  max-width: 500px;
  width: 100%;
}
/* .manHolding img{position: absolute; bottom: 0; right: 0;} */
/* .manHolding{background: url(../public/Image/ManHoldingPhone.png) no-repeat bottom right; background-size: 100%;} */
.businessReviewForm input {
  border: 1px solid #dbdee9;
  height: 50px;
  width: 100%;
  border-radius: 0;
}
.businessReviewForm textarea {
  border: 1px solid #dbdee9;
  height: 140px;
  width: 100%;
  border-radius: 0;
}
.businessReviewForm select {
  border: 1px solid #dbdee9;
  height: 50px;
  width: 100%;
  border-radius: 0;
}

.AboutCountryList li {
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  color: #0094ff;
  padding-bottom: 15px;
  font-family: DM Sans;
}
.preNext {
  border: 1px solid #0094ff;
  padding: 5px 20px;
  border-radius: 25px;
  background: #0094ff;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  height: 45px;
}
.preCancel {
  border: 1px solid #ff5548;
  padding: 5px 20px;
  border-radius: 25px;
  background: #fff;
  color: #ff5548;
  font-size: 18px;
  font-weight: 600;
  width: 140px;
  height: 45px;
}
.preCancel:hover {
  background: #ff5548;
  color: #fff;
  border-color: #ff5548;
}

/*=============================================  Media Query =======================================================*/
@media only screen and (max-width: 1199px) {
  .clenderSection .figcaption {
    font-size: 12px;
  }
  .bannerThum .imageThumbnil {
    width: 100%;
    height: 60px;
  }
  .bannerThum .videoThumbnail {
    width: 100%;
    height: 60px;
  }
  .daysCounting {
    width: 188px;
  }
}

@media only screen and (max-width: 767px) {
  .houseRenovation {
    font-size: 26px;
  }
  .daysCounting span {
    font-size: 13px;
  }
}

.coreTeamMemberPics {
  display: flex;
}

.ChairPersonName p {
  color: #0094ff;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
.RaiseFundIcon {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.RibaFreeFlag {
  margin: 0 auto;
  max-width: 232px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.CommitedSharia {
  position: relative;
  padding: 30px;
  border: 1px solid #dbdee9;
}
.CommitedShariaImage {
  position: absolute;
  right: 200px;
  margin-top: -288px;
}
.CommitedShariaHeading {
  max-width: 450px;
}
.CommitedShariaHeading h6 {
  color: #001a5b;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}
.CommitedShariaHeading p {
  font-size: 14px;
  line-height: 20px;
}
.familyPhoto {
  top: 14px;
}
.SafeandSecured {
  top: 74%;
  background: linear-gradient(
    90deg,
    rgba(1, 26, 91, 0.7) 0.09%,
    rgba(14, 37, 98, 0.35) 99.84%
  );
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  z-index: 11;
  margin-top: -30px;
  position: relative;
}

.HalalDescription {
  display: flex;
  align-items: center;
}
.HalalDescriptionSection {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.HalalDescription p {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-left: 18px;
  text-transform: uppercase;
  white-space: nowrap;
}
.browseProjectTop {
  padding-top: 150px;
  padding-bottom: 100px;
}
.CommitedShariaImage img {
  max-width: 300px;
}
.StepsToFollow h1 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 116.667% */
  letter-spacing: -0.72px;
}
.Numbercircle {
  border-radius: 50%;
  padding: 10px;
  background: #fff;
  color: #fff;
  background: #0094ff;
  position: relative;
}
.SignUptoMabrook {
  display: flex;
  align-items: flex-start;
}
.SignUptoMabrook > div p {
  line-height: 20px;
}
.SignUptoMabrook > div h3 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  letter-spacing: -0.48px;
}
.Numbercircle::after {
  content: "";
  background-color: #dbdee9;
  position: absolute;
  width: 1px;
  height: 80px;
  top: 43px;
  left: 50%;
}
.Numbercirclelast-child {
  border-radius: 50%;
  padding: 10px;
  background: #fff;
  color: #fff;
  background: #0094ff;
  position: relative;
}

/* .newPopup .sharIconSocial .downLoadBtn li img{margin-top: 0;} */
.newPopup .sharIconSocial img {
  position: relative;
  margin-top: 0 !important;
  top: 0;
  right: 0;
}
.popupSectionDolarIcon.newPopup {
  margin-top: 15px;
}

/* ============== */
.HowtoInvestVideoExplainer {
  display: flex;
  align-items: center;
}
.HowtoInvestVideoExplainerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px 10px 0px 10px;
}
.HowtoInvestVideoExplainer img {
  max-width: 100px;
  width: 100%;
}
.HowtoInvestVideoExplainer h3 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 93.75% */
  letter-spacing: -0.64px;
}
.HowtoInvestVideoExplainer P {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}
.HowtoInvestVideoExplainer H6 {
  color: #0094ff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}
.videoPlayerButton img {
  width: 64px;
  height: 64px;
  cursor: pointer;
}
.ChairPersonNameDesigNation {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.ChairPersonNames h4 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 115.385% */
  letter-spacing: -0.52px;
}
.ChairPersonNames h6 {
  color: #0094ff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
}
.ChairPersonNameDesigNationMobileVersion {
  display: flex;
  justify-content: center;
}
.ChairPersonName {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.TeamViewImageSize {
  max-width: 232px;
  height: 232px;
  margin: 0 auto;
}
.ticketTeamBox {
  max-width: 300px;
  height: 350px;
  margin: 0 auto;
  padding: 10px;
}

.ticketTeamBox img {
  max-width: 300px;
  height: 22rem;
}

.quill-editor .ql-container {
  height: 200px;
  width: 100%;
  background-color: white !important;
  color: black !important;
}
.slick-list {
  /* z-index: 111; */
}
.RaisedAmountcardDetails {
  font-size: 25px;
  color: #fff;
}
.RaisedAmountcardDetails .RaisedAmountcardDetailsa {
  font-weight: 600;
}
.raisecapitalpara p {
  font-size: 15px;
  margin-bottom: 10px;
  color: #5F718D;
  font-weight: 400;
}

.raisecapitalborder{
  border: 10px solid;
  border-image-source: linear-gradient(180deg, #B8E0FF 0%, #FFFFFF 100%);
}

.raiseCapital_kyc-pop p{
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #001A5C;
}


.raisecapitalpara {
  margin-top: -80px;
}
.startRaiseCapital {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.houseRenovationPara > h1,
h2 {
  color: #fff;
}
.HalalImageWithPara {
  display: flex;
  align-items: center;
}
.saveCloseButton {
  text-align: right;
}
.userDashboardSection {
  background-image: url(../public/Image/userDashboard.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 200px;
}
.userProfilePicture {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userProfilePictureedit {
  margin-top: -110px;
  margin-left: 32px;
}
.userProfilePictureedit img {
  /* max-width: 140px; */
  width: 140px;
  height: 140px;
  object-fit: cover;
  border: 0px solid #fff;
  border-radius: 80px;
  padding: 1px;
}
.generalInformationDetail {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.linkDinIcon-small img {
  margin-right: 10px;
  cursor: pointer;
}
.userType {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.userprofileName {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.generalInformation {
  margin-left: 200px;
}
.AboutTheInvestor {
  justify-content: space-between;
  display: flex;
}
.GenralInformation p {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 10px;
}
.GenralInformation p:nth-child(2) {
  margin-top: 15px;
}
.AccountTypeDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AccountTypeDetail p {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.AccountTypeDetail h6 {
  color: #001a5c;
  text-align: right;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.AccountType {
  display: grid;
  row-gap: 20px;
}
.privousExprienceDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
}
.privousExprienceDetails h6 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.AchievementsHeading h6 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 66.667% */
  letter-spacing: -0.72px;
}
.AchievementsHeading p {
  margin-top: 15px;
  color: #5f718d;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.riskProfile {
  display: flex;
  justify-content: center;
}
button.nav-link.active {
  background-color: #0094ff !important;
  color: #fff !important;
  /* padding: 10px;
  border-radius: 40px; */
}
.overMain button.nav-link.active {
  border-radius: 20px;
}
.userProfilePictureeditbutton {
  position: relative;
}
.editIcon {
  position: absolute;
  content: "";
  bottom: 15px;
  right: 8px;
}
.editIcon img {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.linkDinIcon-small {
  display: flex;
  justify-content: flex-end;
}
.login a {
  border-radius: 20px;
}
video.videoSection {
  object-fit: fill;
}
.videoImageProjectDetail {
  display: flex;
  margin-top: 20px;
}
/* =============== */

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.socialicon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  padding-left: 31px;
}
.viewMoreAccountType button {
  margin-right: 20px;
}
.changeAccountType img {
  max-width: 367px;
}
/* Notification List */
.NotificationList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NotificationListTimeDate {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.LastRefreshed {
  padding-left: 10px;
}
.recepeintUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recepientUserNameTime {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.recepientUserNameTime > div > p {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  max-width: 250px;
}
.recepientUserNameTime h6 {
  color: #5f718d;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
}
.weekDaysOff {
  border-radius: 30px;
  border: 1px solid #dbdee9;
  background: #f1f8fd;
  width: 28px;
  height: 28px;
}
.reactivatemodal {
  padding: 20px;
  display: flex;
  justify-content: center;
}
.reactivatemodalButton {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.viewmodal h3 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 66.667% */
  letter-spacing: -0.72px;
}
.viewmodal p {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 162.5% */
}
.letsgettogehter h3 {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.54px;
}
.letsgettogehter p {
  color: #001a5c;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: 6px;
  margin-bottom: 8px;
}
.letsgettogehterBox {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.letsgettogehterBox img {
  max-width: 250px;
  width: 100%;
}
.letsgettogehter {
  border: 1px solid #dbdee9;
  background: #f1f8fd;
  padding: 10px;
}
.overMain > button.nav-link.active {
  border-radius: 30px !important;
}
.faEllipsisVertical {
  /* background: transparent; */
  width: 100%;
  max-width: 45px;
}
.divOpenClose {
  margin-top: -8px;
  position: absolute;
  left: -180px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
}
.divOpenClose:last-child {
  /* top: -12px; */
  width: auto;
}
.divOpenClose > a {
  margin: 10px;
  text-decoration: none;
  font-size: 14px;
}
#my-tabs-tab-InvestorAbout {
  border-radius: 20px;
}
#my-tabs-tab-InvestorType {
  border-radius: 20px;
}
#my-tabs-tab-InvestorPrevExperience {
  border-radius: 20px;
}
.basicInformation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dbdee9;
  padding: 5px;
  border-radius: 5px;
  /* background: #f1f8fd; */
}
.basicInformation button{
   background-color: transparent;
  font-weight: 600;
  color: #001A5C;
  font-size: large;
}
.basicInformation h3 {
  font-size: 16px;
}
.basicInformation span {
  cursor: pointer;
}
.documentNumberDigit {
  color: #001a5c;
}
.kyc_created-date {
  display: flex;
  justify-content: space-between;
}
.kycDisplaySection {
  position: relative;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kycInfoSection {
  position: relative;

  display: flex;
  justify-content: center;
}
.kycDisplaySection h1 {
  font-size: 30px;
}
.kecDate {
  font-weight: 600;
  color: #001a5c;
}
.kycDisplaySection p {
  font-size: 18px;
  padding-top: 20px;
  max-width: 40rem;
}
.kycDescription {
  font-size: 16px !important;
  font-weight: 400;
  color: #001a5c;
  /* color: #5f718d; */
}
.kycDescriptionImage {
  position: absolute;
  bottom: -16px;
  right: 119px;
}
.kycFrntBck {
  padding-left: 45px;
  font-size: 21px !important;
  font-weight: 600;
  color: #001a5c;
}
.shariahcompliances {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textarea-container {
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}

.shariahcompliances {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inboxCount {
  position: relative;
  top: -32px;
  left: 106px;
  background: #fff;
  width: 1.4%;
  padding: 1px;
  border-radius: 50px;
  text-align: center;
}
.messageCountr {
  background: #dee8ef;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  color: #001a5c;
  font-weight: 600;
  border: 1px solid #f1f8fd;
}
.NotificationList h1 {
  font-weight: 600;
  font-size: 24px;
}
.tableHeading div {
  font-weight: 500;
}
.forwordreply {
  padding-left: 20px;
  border-radius: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 9px;
  background: transparent;
  border: 1px solid #001a5c;
}
.replyUserIcon {
  display: flex;
  align-items: flex-start;
}
.characterCount {
  position: relative;
  top: -20px;
  text-align: end;
  right: 10px;
}
.profileLogoIcon a {
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 7px;
}
.profileLogoIcon {
  display: flex;
  align-items: center;
}
.dashboardLogoIcon a {
  background-color: #fff;
  padding: 12px;
  border: 5px;
}
.numberOfMesg {
  background-color: #fca311;
    position: absolute;
    top: -16px;
    right: -8px;
    border-radius: 20px;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    width: 22px;
    height: 22px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.numberOfMesgrelative {
  position: relative;
}

/* .messageBox{
  position: relative;
  top: 0;
  right: 0;
} */
.youtubeLink {
  height: calc(100% - 563px);
}
/* .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40x;
  height: 40px;
} */

.char-count {
  font-size: 12px;
  color: #888;
  margin-top: 5px;
}
.nomieeCard {
  background-color: #f0f8ff;
  /* width: 392px; */
  height: 326px;
  /* left: 43px; */
  border: 1px dashed #0094ff;
}
.uploadButton {
  background-color: #0094ff;
  width: 143px;
  height: 41px;
  border-radius: 50px;
  border: 1px;
}
.uploadImage {
  width: 68px;
  height: 68px;
  margin-top: 74px;
}
.selectIncome {
  border-color: #0094ff;
  /* background-color: #0094FF; */
}
.incomeOption {
  border: 1px solid #dbdee9;
  width: 15rem;
  height: 56px;
  text-align: center;
  padding-top: 13px;
  margin-right: 14px;
  margin-top: 10px;
  display: flex;
}
.uploadIncome {
  background-color: #f0f8ff;
  /* width: 392px; */
  height: 100px;
  border: 1px;
}
.incomeButton {
  width: 100%;
  outline: rgb(0, 148, 255);
  color: #0094ff;
  border: 1px solid #0094ff;
}
/*=============== Nav Bar =======================*/
.nav_2 {
  /* position: fixed; */
  top: 0;
  left: -100%;
  /* height: 100vh; */
  /* height: 100vh; */
  padding: 1rem 1rem 0;
  background-color: #fff;
  /* box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1); */
  transition: 0.4s;
  z-index: 2;
}
.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none;
  flex: 0 0 100%;
  /* margin-top: 85px; */
}
.nav__container::-webkit-scrollbar {
  display: none;
}
/* .nav__list, 
  .nav__items {display: grid;} */
.nav__list {
  row-gap: 2.5rem;
}
.nav__items {
  row-gap: 1.5rem;
  /* margin-bottom: 15px; */
}
.nav__subtitle {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: #a5a1aa;
}
.nav__link {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
}
.nav__link:hover {
  color: #000;
  background: #000;
}
.nav__icon {
  font-size: 1.2rem;
  margin-right: 0rem;
}
.nav__container .icon_cmn {
  width: 54px;
  height: 54px;
  line-height: 54px;
  flex: 0 0 54px;
  text-align: center;
  border-radius: 8px;
}
.nav__name {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: #001a5c;
}
.nav__option {
  font-weight: 400;
  color: #0094ff;
  font-size: 16px;
}
.nav__logout {
  margin-top: 5rem;
}
.header__toggle {
  display: none;
  background: #000;
  position: fixed;
  top: 80px;
  padding: 5px;
  display: block;
  color: #fff;
  border-radius: 0 5px 4px 0px;
  cursor: pointer;
}
.toggleClose {
  display: none;
  background: #f5f5f5;
  font-size: 20px;
  position: absolute;
  top: 72px;
  padding: 5px;
  right: 9px;
  width: 35px;
  height: 35px;
  line-height: 20px;
  color: #000;
  border-radius: 50%;
  cursor: pointer;
}
/* Dropdown */
.nav__dropdown {
  overflow: hidden;
  max-height: 30px;
  transition: 0.4s ease-in-out;
}
.nav__dropdown_collapse {
  background-color: #fff;
  border-radius: 0.25rem;
  margin-top: 0rem;
}
.nav__dropdown_content {
  display: grid;
  row-gap: 0;
  padding: 0rem 0rem 0rem 0rem;
  margin-left: 0px;
}
.nav__dropdown_item {
  font-size: 13px;
  font-weight: normal;
  color: #000;
  text-decoration: none;
  line-height: 22px;
  padding: 0 0 0 60px;
  margin-bottom: 15px;
}
.nav__dropdown_item svg {
  display: none;
}
.nav__dropdown_item:hover {
  color: #000;
}
.nav__dropdown-icon {
  margin-left: auto;
  transition: 0.4s;
}
/* Show dropdown collapse */
.nav__dropdown.open {
  max-height: 100rem;
}
/* Rotate icon arrow */
.nav__dropdown.open svg {
  transform: rotate(180deg);
}
/* .nav__dropdown.open .nav__dropdown_content svg {
    transform: rotate(0deg);
  } */
/*===== Show menu =====*/
.showmenu {
  left: 0;
}
/*===== Active link =====*/
/* .nav__list .active .icon_cmn {
  background: #316cf4;
  color: #fff;
  border-radius: 0px;
} */
.nav__list .active,
.nav__link:hover {
  background: #68b2ef;
  color: #000;
  width: 261px;
  height: 37px;
  border-radius: 5px;
}
.nav__list .activeLink .nav__name {
  color: #5dabeb;
}
.nav__list .active,
.nav__link:hover span {
  color: #000;
}
.nav__list .active .nav__icon,
.nav__list .active .nav__icon {
  border-radius: 5px;
  background: rgba(248, 248, 255, 1);
  color: #000;
}
.nav__list .active,
.nav__link:hover span {
  color: #fff;
}
.nav__list .active .nav__icon,
.nav__list .active .nav__icon {
  border-radius: 5px;
  background: #316cf4;
  color: #fff;
}
/* .nav__list .active .nav__icon img {
    filter: brightness(0) invert(1);
  } */
/* .nav__link:hover img {
    filter: brightness(0) invert(1);
  } */
.nav__list .active .nav__name {
  color: #fff;
}
.customeButton {
  border-radius: 21px;
  height: 40px;
  /* width: 100%; */
  width: 135px;
}
.kyc_button {
  display: flex;
  justify-content: center;
}
.customeFormCheck {
  margin: 0;
  padding: 0;
}

.img-fluid-investorProfile {
  float: inline-end;
  width: 432px;
  height: 275px;
}
.ancherUnderline {
  text-decoration: underline;
}
.form-check-label {
  color: #001a5c;
}
.uploadfileName {
  color: #0094ff;
  font-weight: 400;
  /* padding-left: 45px; */
}
.uploadfileName .ellipse {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboardLogoIcon {
  display: flex;
  flex-direction: column;
}
.totalDocument {
  color: #001a5c;
  font-weight: 600;
  font-size: 16px;
  display: flex;
}
.actionDot {
  color: white;
  border: 1px solid gray;
  border-radius: 27px;
  height: 38px;
  width: 3rem;
  background: #0094ff;
}
.cardFillter {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.modalDocument {
  color: white;
  background: #0094ff;
  width: 30px;
  height: 30px;
  border-radius: 14px;
  padding: 7px;
}
.tableColor {
  color: #001a5c;
  font-weight: 500;
}
.dashboardTotalColor {
  color: #001a5c;
  padding-left: 4px;
}

.dates {
  background-color: #0D6EFD;
    border-radius: 10px;
    font-size: 13px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
}
.tow_facto-auth{
  background: #C02E241A;
  color: #C02E24;
  font-size: 18px;
  font-weight: 400;
  border-radius: 50px;
}
.otpBoxHt {
  height: 4rem;
  text-align: center;
  font-size: 28px;
  color: #001A5C;
  font-weight: 400;

}
.paddingInline {
  padding-inline-start: revert;
}
.disabled-icon {
  color: gray;
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer;
}
.enabled-icon{
  color: red;
}
.customeBasicInformation {
  border: none;
  margin-top: 100px;
}
.customLable label{
 color: #001A5C;
 font-weight: 500;
font-size: 16px;
}
.ViewGroup label{
  color: #001A5C;
  font-weight: 400;
  font-size: 16px;
}
.ViewGroup input[type ="checkbox"]{
  width: 20px;
  height: 20px;
  border: 2px solid #0094FF;
}
.users-list input[type ="checkbox"]{
  width: 20px;
  height: 20px;
  border: 2px solid #0094FF;
}
.addedmember span{
  color: #001A5C;
  font-weight: 400;
  font-size: 16px;
}
.customeFileUpload input[type="file"]{
  background: #E9F1FF;
  border: 2px dashed #5F718D;
}
.customeNotification img{
  max-width: 450px;
  width: 100%;
  object-fit: fill;
  margin: 0 auto;
  display: flex;
  height: 100%;
}
.customeNotification iframe{
  width: 100%;
  height: 632px;
}
.css-1nmdiq5-menu .css-1n6sfyn-MenuList .css-10wo9uf-option img{
  border-radius: 50%;
  height: 20px;
  margin-right: 5px;
  width: 100%;
  max-width: 20px;
}
.css-1nmdiq5-menu .css-1n6sfyn-MenuList .css-d7l1ni-option img{
  border-radius: 50%;
  height: 20px;
  margin-right: 5px;
  width: 100%;
  max-width: 20px;
}
.css-1p3m7a8-multiValue .css-wsp0cs-MultiValueGeneric img{
  width: 100%;
  max-width: 20px;
}
.disableDocument{
 display: none;
}
.customeActionButton{
  position: relative;
}
.customeEllipsisVertical{
  position: absolute;
  top: 0;
  left: -11px;
}

.Criteria_Account ul {
  padding: 0;
  margin: 0;
  color: #5F718D;
}
.Criteria_Account p {
  color: #5F718D;
}
.Criteria_Account ul li::marker{
  color: #0094FF;
  font-weight: 600;
}
.notification_areaBox{
  padding: 15px;
  max-width: 500px;
  width: 100%;
  background: #fff;
  height: 300px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* HighRisk Assestment */

.timeline {
  position: relative;
}
.containerTimeLine.left {
  left: 14%;
}
.containerTimeLine {
  padding: 15px 13px;
  position: relative;
  background: inherit;
  width: 100%;
  max-width: 210px;
}

.containerTimeLine .content {
  
  background: #ffffff;
  position: relative;
  border: 1px solid #eeeeee;
  border-left: 5px solid #eeeeee;
  border-radius: 25px 0 0 25px;
  height: 50px; 
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerTimeLine .content h2 {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #001a5c;
}
.containerTimeLine.right {
  left: 48%;
}
.containerTimeLine.right .content {
  border-right: 5px solid #eeeeee;
  border-left: 5px solid #001a5c;
  border-radius: 0 15px 15px 0;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 12px;
  /* background: #001a5c; */
  background: rgb(97, 185, 86);
  background: linear-gradient(0deg, rgba(97, 185, 86, 1) 0%, rgba(241, 202, 55, 1) 49%, rgba(206, 77, 73, 1) 100%);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
  border-radius: 25px;
}
.selectedAccountType{
  background: #f1f8fd;
}
.updateStatusColorbtn{
  color: #C17B08;
  border: #FCA311;
  background: rgba(252, 163, 17, 0.10);
}
/* Document Management */
.custome-document_mgt{
  display: flex;
  align-items: center;
  justify-content: left;
}
.loading-background {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 99 !important;
  cursor: progress !important;
  background: rgba(0, 0, 0, 0.8)!important;
}
.ContactNumber{
  position: relative;
}
.ContactNumberbutton{
  position: absolute;
  top: 0px;
  right: 0;
}
.Contactemailbutton{
  position: absolute;
  top: 30px;
  right: 0;
}
/* =============circuler time============= */
.circle-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 200px;
  height: 200px;
}

.circle {
  width: 155px;
  height: 155px;
}

.circle__background,
.circle__progress {
  fill: none;
  stroke-width: 10;
}

.circle__background {
  stroke: #e6e6e6;
}

.circle__progress {
  stroke: #00227a;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  transition: stroke-dashoffset 1s linear;
}

.circle-timer__text {
  position: absolute;
  font-size: 24px;
  font-weight: 400;
}
.options-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}



.lost-access {
  text-align: center;
  margin-top: 20px;
}

.lost-access a {
  color: #1d643b;
  text-decoration: underline;
}



.subtitleChannel {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
  text-align: center;
}

.option-item {
  /* display: flex;
  align-items: center;
  justify-content: left; */
  padding: 10px 20px;
  border: 2px solid #DBDEE9;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 480px;
  width: 100%;
  color: #5F718D;
}
.option-item:hover {
  /* background-color: #f0f0f0; */
  background-color: transparent;
  border: 2px solid #0094FF;
}
.highlighted {
  border: 2px solid #0094FF;
}

.option-item img {
  margin-right: 10px;
}
.disable-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #d0d0d0;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: not-allowed;
  margin-top: 20px;
  width: -webkit-fill-available;

}
.confirm-button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #0094FF;
  color: #fff;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  width: -webkit-fill-available;
}
.choose_another-way{
  font-weight: 600;
  font-size: 30px;
  color: #001A5C;
  text-align: center;
}
.choose_another-ways{
  max-width: 500px;
  margin: 0 auto;
}
/* =============== */
.faq-search{
  height: 53px;
}
.searchIcon-faq{
  position: absolute;
  top: 15px;
  left: 10px;
}
.faq-input input{
  padding: 0px 40px;
}
.faqcontainer{
  padding-top: 40px;
}
.faq_heading{
  color: #001A5C;
  font-size: 45px;
  line-height: 60px;
  font-style: normal;
  font-weight: 400;
}
.faq_para{
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
color: #5F718D;
}
/* ===========New FAQ List======== */
.container-faq {
  display: flex;
  border: 1px solid #DBDEE9; /* Border around the whole container */
  width: 100%; /* Full width container */
  background-color: #fff;
}

.left-column-faq {
  width: 20%;
  border-right: 1px solid #DBDEE9; /* Border between columns */
  padding: 20px;
}

.right-column-faq {
  width: 80%;
  padding: 10px;
}
.left-column-faq ul{
  list-style-type: none;
}
.left-column-faq ul li{
line-height: 40px;
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #001A5C;
padding: 0px 14px;
cursor: pointer;
}
.faq-highlight{
  background-color: #E8F7FF;
  color: #001A5C !important;
  font-weight: 600 !important;
  padding: 0px 15px;
}
.faq-question-answer{
  color: #001A5C;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  padding: 0px 20px;
}
.faq-questions{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #001A5C;
  cursor: pointer;
  padding: 0px 20px;
}
.faq-answer{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #5F718D;
  padding: 0px 20px;
}
/* =============faq section=========== */
.showClearButton{
    position: absolute;
    right: 15px;
    top: 30%;
    cursor: pointer;
    font-size: 18px;
    color: red
}
.faq-social-icon img{
  margin-right: 10px;
  cursor: pointer;
}
/* ================ */
.faqbutton {
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #0094FF;
  background-color: #fff;
  cursor: pointer;
  color:#0094FF;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 30%;
  border-radius: 5px;
}

/* Style for active button */
.activefaq {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.video-title{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 16px;  
  color: #001A5C;
}
.video-para{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #5F718D;
}
.dateFormate{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #5F718D;  
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-color: #000; /* Optional: To have a dark background */
}

/* Video player styling with no borders */
.video-player {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  height: auto;
  border: none;
  outline: none; /* Remove any default focus outline */
  background-color: transparent; /* Ensure the video player has no background */
  box-shadow: none; /* No shadows */
}

/* ========================== */
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ==============Pagination=========== */
.pagination {
  display: flex;
  gap: 5px;
  margin: 20px 0;
}

.pagination-button {
  padding: 8px 8px;
  background-color: transparent;
  border: 1px solid #001A5C;
  cursor: pointer;
  width: 40px;
  border-radius: 30px;
}

.pagination-button.activep {
  background-color: #007bff;
  color: white;
  border: 1px solid transparent;
}

.pagination-button:disabled {
  /* background-color: #eee; */
  cursor: not-allowed;
}
.faqSearchItem{
  background-color: #fff;
  margin-top: 0px;
  padding: 15px;
  border-radius: 8px;
}
.faqSearchItem .faq-para{
  color: #001a5c;
  font-size: 16px;
  font-weight: 600;
}
.checkbox_kyc-paperWork{
  border: 1px solid #DBDEE9;
  padding: 10px;
}
.paperwork_desc{
  color: #001A5C;
}
.plateform_sharia_compliance{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.shariah_compliance-heading{
  margin-top: 150px;
}