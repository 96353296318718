@import url(https://fonts.googleapis.com/css?family=Montserrat);

.form-container {
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4); */
  display: flex;
  flex-direction: column;
}

.form-container .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}
.form-container .footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: rgb(255, 0, 140);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}
.sign-up-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.personal-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* PROGRESS BAR */
.progressbar {
  width: 100%;
  height: 10px;
  background-color: #eeeeee;
  /* margin-bottom: 50px; */
  -webkit-appearance: none;
  appearance: none;
  position: relative;
}
.progressbar::-webkit-progress-bar{
  background-color: lightcoral;
  border-radius: 10px;
}
.progressbar::-webkit-progress-value{
  background-color: limegreen;
  border-radius: 10px;
}
.progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(12, 215, 178);
}
.other-info-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div > label.form-label{
  font-size: 13px;
}
.percentageNumber{
  padding-left: 12px;
  font-size: 14px;
}
